export const classicLightCssVars = {
  '--trellis-box-shadow-100': '0 1px 5px 0 rgba(44,61,79,0.12)',
  '--trellis-box-shadow-200': '0 1px 4px 0 rgba(66,91,117,0.06)',
  '--trellis-box-shadow-300': '0 3px 8px 0 rgba(0,0,0,0.1)',
  '--trellis-box-shadow-400': '0 1px 24px 0 rgba(0,0,0,0.08)',
  '--trellis-color-fill-surface-default': '#ffffff',
  '--trellis-color-fill-surface-default-hover': '#242424',
  '--trellis-color-fill-surface-default-pressed': '#292929',
  '--trellis-color-fill-surface-recessed': '#253342',
  '--trellis-color-fill-surface-raised': '#F5F8FA',
  '--trellis-color-fill-surface-overlay': '#333333',
  '--trellis-color-fill-primary-default': '#FF7A59',
  '--trellis-color-fill-primary-hover': '#FF8F73',
  '--trellis-color-fill-primary-pressed': '#E66E50',
  '--trellis-color-fill-primary-disabled': '#EAF0F6',
  '--trellis-color-fill-primary-subtle': '#FFF1EE',
  '--trellis-color-fill-secondary-default': '#00A4BD',
  '--trellis-color-fill-secondary-hover': '#7FD1DE',
  '--trellis-color-fill-secondary-pressed': '#0091AE',
  '--trellis-color-fill-secondary-disabled': '#EAF0F6',
  '--trellis-color-fill-secondary-subtle': '#E5F5F8',
  '--trellis-color-fill-tertiary-default': '#EAF0F6',
  '--trellis-color-fill-tertiary-hover': '#F5F8FA',
  '--trellis-color-fill-tertiary-pressed': '#CBD6E2',
  '--trellis-color-fill-tertiary-disabled': '#EAF0F6',
  '--trellis-color-fill-tertiary-subtle': '#DFE3EB',
  '--trellis-color-fill-brand-default': '#FF7A59',
  '--trellis-color-fill-brand-hover': '#FF8F73',
  '--trellis-color-fill-brand-pressed': '#E66E50',
  '--trellis-color-fill-brand-subtle': '#FFF1EE',
  '--trellis-color-fill-positive-default': '#00BDA5',
  '--trellis-color-fill-positive-hover': '#7FDED2',
  '--trellis-color-fill-positive-pressed': '#00A38D',
  '--trellis-color-fill-positive-subtle': '#E5F8F6',
  '--trellis-color-fill-positive-alt': '#38D84C',
  '--trellis-color-fill-caution-default': '#F5C26B',
  '--trellis-color-fill-caution-hover': '#FAE0B5',
  '--trellis-color-fill-caution-pressed': '#DBAE60',
  '--trellis-color-fill-caution-subtle': '#FEF8F0',
  '--trellis-color-fill-caution-alt': '#FCCB57',
  '--trellis-color-fill-alert-default': '#F2545B',
  '--trellis-color-fill-alert-hover': '#FF5962',
  '--trellis-color-fill-alert-pressed': '#D94C53',
  '--trellis-color-fill-alert-subtle': '#FDEDEE',
  '--trellis-color-fill-info-default': '#00A4BD',
  '--trellis-color-fill-info-hover': '#7FD1DE',
  '--trellis-color-fill-info-pressed': '#0091AE',
  '--trellis-color-fill-info-subtle': '#E5F5F8',
  '--trellis-color-fill-accent-purple-default': '#6A78D1',
  '--trellis-color-fill-accent-purple-hover': '#B4BBE8',
  '--trellis-color-fill-accent-purple-pressed': '#5E6AB8',
  '--trellis-color-fill-accent-purple-subtle': '#F0F1FA',
  '--trellis-color-fill-accent-orange-default': '#FF7A59',
  '--trellis-color-fill-accent-orange-hover': '#FFBCAC',
  '--trellis-color-fill-accent-orange-pressed': '#E66E50',
  '--trellis-color-fill-accent-orange-subtle': '#FFF1EE',
  '--trellis-color-fill-accent-green-default': '#00BDA5',
  '--trellis-color-fill-accent-green-hover': '#7FDED2',
  '--trellis-color-fill-accent-green-pressed': '#00A38D',
  '--trellis-color-fill-accent-green-subtle': '#E5F8F6',
  '--trellis-color-fill-accent-yellow-default': '#F5C26B',
  '--trellis-color-fill-accent-yellow-hover': '#FAE0B5',
  '--trellis-color-fill-accent-yellow-pressed': '#DBAE60',
  '--trellis-color-fill-accent-yellow-subtle': '#FEF8F0',
  '--trellis-color-fill-accent-red-default': '#F2545B',
  '--trellis-color-fill-accent-red-hover': '#F8A9AD',
  '--trellis-color-fill-accent-red-pressed': '#D94C53',
  '--trellis-color-fill-accent-red-subtle': '#FDEDEE',
  '--trellis-color-fill-accent-blue-default': '#00A4BD',
  '--trellis-color-fill-accent-blue-hover': '#7FD1DE',
  '--trellis-color-fill-accent-blue-pressed': '#0091AE',
  '--trellis-color-fill-accent-blue-subtle': '#E5F5F8',
  '--trellis-color-fill-accent-teal-default': '#007C7D',
  '--trellis-color-fill-accent-teal-hover': '#006162',
  '--trellis-color-fill-accent-teal-pressed': '#00494B',
  '--trellis-color-fill-accent-teal-subtle': '#051919',
  '--trellis-color-fill-accent-magenta-default': '#F2547D',
  '--trellis-color-fill-accent-magenta-hover': '#F9AABE',
  '--trellis-color-fill-accent-magenta-pressed': '#D94C71',
  '--trellis-color-fill-accent-magenta-subtle': '#FDEDF2',
  '--trellis-color-fill-transparent-default': 'rgba(0,0,0,0)',
  '--trellis-color-fill-field-default': '#F5F8FA',
  '--trellis-color-fill-field-hover': '#F5F8FA',
  '--trellis-color-fill-field-pressed': '#F5F8FA',
  '--trellis-color-fill-field-disabled': '#EAF0F6',
  '--trellis-color-text-primary-default': '#33475B',
  '--trellis-color-text-primary-subtle': '#516F90',
  '--trellis-color-text-primary-disabled': '#99ACC2',
  '--trellis-color-text-primary-on-fill-default': '#ffffff',
  '--trellis-color-text-primary-on-fill-subtle': '#B6B1AF',
  '--trellis-color-text-interactive-default': '#0091AE',
  '--trellis-color-text-interactive-default-hover': '#007A8C',
  '--trellis-color-text-interactive-default-pressed': '#7FD1DE',
  '--trellis-color-text-interactive-on-fill-default': '#FFFFFF',
  '--trellis-color-text-interactive-on-fill-subtle': '#C4B4F7',
  '--trellis-color-text-brand-default': '#FF7A59',
  '--trellis-color-text-brand-on-fill-default': '#ffffff',
  '--trellis-color-text-brand-on-fill-subtle': '#FFA581',
  '--trellis-color-text-positive-default': '#00BDA5',
  '--trellis-color-text-positive-on-fill-default': '#ffffff',
  '--trellis-color-text-positive-on-fill-subtle': '#38D84C',
  '--trellis-color-text-positive-on-fill-alt': '#042905',
  '--trellis-color-text-caution-default': '#D39913',
  '--trellis-color-text-caution-on-fill-default': '#1F1F1F',
  '--trellis-color-text-caution-on-fill-subtle': '#EEB117',
  '--trellis-color-text-caution-on-fill-alt': '#331E06',
  '--trellis-color-text-alert-default': '#F2545B',
  '--trellis-color-text-alert-on-fill-default': '#FFFFFF',
  '--trellis-color-text-alert-on-fill-subtle': '#FF7B70',
  '--trellis-color-text-info-default': '#5CAAFF',
  '--trellis-color-text-info-on-fill-default': '#FFFFFF',
  '--trellis-color-text-info-on-fill-subtle': '#8CC4F4',
  '--trellis-color-text-accent-green-default': '#27BD3E',
  '--trellis-color-text-accent-green-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-green-on-fill-subtle': '#38D84C',
  '--trellis-color-text-accent-yellow-default': '#D39913',
  '--trellis-color-text-accent-yellow-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-yellow-on-fill-subtle': '#EEB117',
  '--trellis-color-text-accent-red-default': '#FF7B70',
  '--trellis-color-text-accent-red-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-red-on-fill-subtle': '#FFA499',
  '--trellis-color-text-accent-blue-default': '#5CAAFF',
  '--trellis-color-text-accent-blue-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-blue-on-fill-subtle': '#8CC4F4',
  '--trellis-color-text-accent-teal-default': '#21B7B7',
  '--trellis-color-text-accent-teal-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-teal-on-fill-subtle': '#48D1CF',
  '--trellis-color-text-accent-magenta-default': '#FF72BA',
  '--trellis-color-text-accent-magenta-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-magenta-on-fill-subtle': '#FF9FCC',
  '--trellis-color-text-accent-purple-default': '#AC98F8',
  '--trellis-color-text-accent-purple-on-fill-default': '#FFFFFF',
  '--trellis-color-text-accent-purple-on-fill-subtle': '#C4B4F7',
  '--trellis-color-text-core-default': '#33475B',
  '--trellis-color-text-core-subtle': '#516F90',
  '--trellis-color-text-core-disabled': '#99ACC2',
  '--trellis-color-text-secondary-default': '#FF7A59',
  '--trellis-color-text-secondary-disabled': '#99ACC2',
  '--trellis-color-text-tertiary-default': '#516F90',
  '--trellis-color-text-tertiary-disabled': '#99ACC2',
  '--trellis-color-icon-primary-default': '#7C98B6',
  '--trellis-color-icon-primary-hover': '#7C98B6',
  '--trellis-color-icon-primary-pressed': '#33475B',
  '--trellis-color-icon-primary-disabled': '#99ACC2',
  '--trellis-color-icon-primary-on-fill-default': '#ffffff',
  '--trellis-color-icon-primary-on-fill-subtle': '#B6B1AF',
  '--trellis-color-icon-interactive-default': '#0091AE',
  '--trellis-color-icon-interactive-hover': '#007A8C',
  '--trellis-color-icon-interactive-pressed': '#7FD1DE',
  '--trellis-color-icon-interactive-on-fill-default': '#ffffff',
  '--trellis-color-icon-interactive-on-fill-subtle': '#C4B4F7',
  '--trellis-color-icon-brand-default': '#FFA581',
  '--trellis-color-icon-brand-on-fill-default': '#411204',
  '--trellis-color-icon-brand-on-fill-subtle': '#FFA581',
  '--trellis-color-icon-positive-default': '#38D84C',
  '--trellis-color-icon-positive-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-positive-on-fill-subtle': '#38D84C',
  '--trellis-color-icon-positive-on-fill-alt': '#042905',
  '--trellis-color-icon-caution-default': '#EEB117',
  '--trellis-color-icon-caution-on-fill-default': '#1F1F1F',
  '--trellis-color-icon-caution-on-fill-subtle': '#EEB117',
  '--trellis-color-icon-caution-on-fill-alt': '#331E06',
  '--trellis-color-icon-alert-default': '#F2545B',
  '--trellis-color-icon-alert-hover': '#F8A9AD',
  '--trellis-color-icon-alert-pressed': '#D94C53',
  '--trellis-color-icon-alert-on-fill-default': '#ffffff',
  '--trellis-color-icon-alert-on-fill-subtle': '#FF7B70',
  '--trellis-color-icon-info-default': '#8CC4F4',
  '--trellis-color-icon-info-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-info-on-fill-subtle': '#8CC4F4',
  '--trellis-color-icon-accent-green-default': '#38D84C',
  '--trellis-color-icon-accent-green-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-accent-green-on-fill-subtle': '#38D84C',
  '--trellis-color-icon-accent-yellow-default': '#EEB117',
  '--trellis-color-icon-accent-yellow-on-fill-default': '#1F1F1F',
  '--trellis-color-icon-accent-yellow-on-fill-subtle': '#EEB117',
  '--trellis-color-icon-accent-red-default': '#FF3842',
  '--trellis-color-icon-accent-red-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-accent-red-on-fill-subtle': '#FF7B70',
  '--trellis-color-icon-accent-blue-default': '#8CC4F4',
  '--trellis-color-icon-accent-blue-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-accent-blue-on-fill-subtle': '#8CC4F4',
  '--trellis-color-icon-accent-teal-default': '#48D1CF',
  '--trellis-color-icon-accent-teal-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-accent-teal-on-fill-subtle': '#48D1CF',
  '--trellis-color-icon-accent-magenta-default': '#FF9FCC',
  '--trellis-color-icon-accent-magenta-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-accent-magenta-on-fill-subtle': '#FF9FCC',
  '--trellis-color-icon-accent-purple-default': '#C4B4F7',
  '--trellis-color-icon-accent-purple-on-fill-default': '#FFFFFF',
  '--trellis-color-icon-accent-purple-on-fill-subtle': '#C4B4F7',
  '--trellis-color-icon-accent-orange-default': '#FFA581',
  '--trellis-color-icon-accent-orange-on-fill-default': '#411204',
  '--trellis-color-icon-accent-orange-on-fill-subtle': '#FFA581',
  '--trellis-color-icon-core-default': '#33475B',
  '--trellis-color-icon-core-subtle': '#7C98B6',
  '--trellis-color-icon-secondary-default': '#FF7A59',
  '--trellis-color-icon-secondary-disabled': '#99ACC2',
  '--trellis-color-icon-tertiary-default': '#516F90',
  '--trellis-color-icon-tertiary-disabled': '#99ACC2',
  '--trellis-color-border-primary-default': '#CBD6E2',
  '--trellis-color-border-primary-subtle': '#DFE3EB',
  '--trellis-color-border-primary-on-fill': '#ffffff',
  '--trellis-color-border-primary-disabled': '#EAF0F6',
  '--trellis-color-border-primary-subtle-default': '#FFFFFF',
  '--trellis-color-border-primary-subtle-hover': '#FFFFFF',
  '--trellis-color-border-primary-subtle-pressed': '#7C98B6',
  '--trellis-color-border-primary-on-fill-default': '#FFFFFF',
  '--trellis-color-border-interactive-default': '#7FD1DE',
  '--trellis-color-border-interactive-pressed': '#0091AE',
  '--trellis-color-border-brand-default': '#FF7A59',
  '--trellis-color-border-positive-default': '#00BDA5',
  '--trellis-color-border-caution-default': '#F5C26B',
  '--trellis-color-border-alert-default': '#F2545B',
  '--trellis-color-border-info-default': '#00A4BD',
  '--trellis-color-border-surface-default': '#FFFFFF',
  '--trellis-color-border-core-default': '#CBD6E2',
  '--trellis-color-border-core-subtle': '#DFE3EB',
  '--trellis-color-border-core-hover': '#7C98B6',
  '--trellis-color-border-core-pressed': '#EAF0F6',
  '--trellis-color-border-secondary-default': '#FF7A59',
  '--trellis-color-border-secondary-disabled': 'rgba(0,0,0,0)',
  '--trellis-color-border-tertiary-default': '#CBD6E2',
  '--trellis-color-border-tertiary-disabled': 'rgba(0,0,0,0)',
  '--trellis-color-specialty-notification': '#D9002B',
  '--trellis-color-specialty-scrim': '#000000',
  '--trellis-border-radius-0': '0px',
  '--trellis-border-radius-100': '3px',
  '--trellis-border-radius-200': '4px',
  '--trellis-border-radius-300': '100px',
  '--trellis-border-width-0': '0px',
  '--trellis-border-width-100': '1px',
  '--trellis-border-width-200': '2px',
  '--trellis-border-width-300': '4px',
  '--trellis-space-0': '0px',
  '--trellis-space-25': '1px',
  '--trellis-space-50': '2px',
  '--trellis-space-100': '4px',
  '--trellis-space-200': '8px',
  '--trellis-space-250': '10px',
  '--trellis-space-300': '12px',
  '--trellis-space-400': '16px',
  '--trellis-space-500': '20px',
  '--trellis-space-600': '24px',
  '--trellis-space-700': '28px',
  '--trellis-space-800': '32px',
  '--trellis-space-900': '36px',
  '--trellis-space-1000': '40px',
  '--trellis-space-1100': '48px',
  '--trellis-space-1200': '56px',
  '--trellis-space-1300': '60px',
  '--trellis-space-1400': '64px',
  '--trellis-space-1500': '96px',
  '--trellis-space-1600': '144px',
  '--trellis-space-1700': '192px',
  '--trellis-typography-display-100-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-display-100-font-weight': '500',
  '--trellis-typography-display-100-font-size': '56px',
  '--trellis-typography-display-100-line-height': '72px',
  '--trellis-typography-display-100-letter-spacing': '-0.32px',
  '--trellis-typography-display-200-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-display-200-font-weight': '500',
  '--trellis-typography-display-200-line-height': '76px',
  '--trellis-typography-display-200-font-size': '66px',
  '--trellis-typography-display-200-letter-spacing': '-0.32px',
  '--trellis-typography-display-300-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-display-300-font-weight': '500',
  '--trellis-typography-display-300-font-size': '76px',
  '--trellis-typography-display-300-line-height': '88px',
  '--trellis-typography-display-300-letter-spacing': '-0.32px',
  '--trellis-typography-display-400-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-display-400-font-weight': '500',
  '--trellis-typography-display-400-font-size': '84px',
  '--trellis-typography-display-400-line-height': '96px',
  '--trellis-typography-display-400-letter-spacing': '-0.32px',
  '--trellis-typography-display-500-font-family': 'Lexend Deca',
  '--trellis-typography-display-500-font-weight': '500',
  '--trellis-typography-display-500-font-size': '94px',
  '--trellis-typography-display-500-line-height': '100px',
  '--trellis-typography-display-500-letter-spacing': '-0.32px',
  '--trellis-typography-heading-25-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-25-font-weight': '600',
  '--trellis-typography-heading-25-font-size': '12px',
  '--trellis-typography-heading-25-line-height': '18px',
  '--trellis-typography-heading-25-letter-spacing': '0px',
  '--trellis-typography-heading-50-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-50-font-weight': '600',
  '--trellis-typography-heading-50-font-size': '14px',
  '--trellis-typography-heading-50-line-height': '18px',
  '--trellis-typography-heading-50-letter-spacing': '0px',
  '--trellis-typography-heading-100-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-100-font-weight': '600',
  '--trellis-typography-heading-100-font-size': '16px',
  '--trellis-typography-heading-100-line-height': '20px',
  '--trellis-typography-heading-100-letter-spacing': '0px',
  '--trellis-typography-heading-200-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-200-font-weight': '500',
  '--trellis-typography-heading-200-font-size': '18px',
  '--trellis-typography-heading-200-line-height': '24px',
  '--trellis-typography-heading-200-letter-spacing': '0px',
  '--trellis-typography-heading-300-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-300-font-weight': '600',
  '--trellis-typography-heading-300-font-size': '20px',
  '--trellis-typography-heading-300-line-height': '24px',
  '--trellis-typography-heading-300-letter-spacing': '0px',
  '--trellis-typography-heading-400-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-400-font-weight': '500',
  '--trellis-typography-heading-400-font-size': '22px',
  '--trellis-typography-heading-400-line-height': '27px',
  '--trellis-typography-heading-400-letter-spacing': '0px',
  '--trellis-typography-heading-500-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-500-font-weight': '300',
  '--trellis-typography-heading-500-font-size': '24px',
  '--trellis-typography-heading-500-line-height': '29px',
  '--trellis-typography-heading-500-letter-spacing': '0px',
  '--trellis-typography-heading-600-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-600-font-weight': '700',
  '--trellis-typography-heading-600-font-size': '32px',
  '--trellis-typography-heading-600-line-height': '39px',
  '--trellis-typography-heading-600-letter-spacing': '0px',
  '--trellis-typography-heading-700-font-size': '36px',
  '--trellis-typography-heading-700-line-height': '44px',
  '--trellis-typography-heading-700-font-weight': '500',
  '--trellis-typography-heading-700-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-700-letter-spacing': '-0.16px',
  '--trellis-typography-heading-800-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-800-font-weight': '500',
  '--trellis-typography-heading-800-font-size': '42px',
  '--trellis-typography-heading-800-line-height': '52px',
  '--trellis-typography-heading-800-letter-spacing': '-0.16px',
  '--trellis-typography-heading-900-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-900-font-weight': '500',
  '--trellis-typography-heading-900-font-size': '46px',
  '--trellis-typography-heading-900-line-height': '56px',
  '--trellis-typography-heading-900-letter-spacing': '-0.16px',
  '--trellis-typography-heading-1000-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-heading-1000-font-weight': '700',
  '--trellis-typography-heading-1000-font-size': '55px',
  '--trellis-typography-heading-1000-line-height': '67px',
  '--trellis-typography-heading-1000-letter-spacing': '0px',
  '--trellis-typography-body-100-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-100-font-weight': '300',
  '--trellis-typography-body-100-line-height': '24px',
  '--trellis-typography-body-100-letter-spacing': '0px',
  '--trellis-typography-body-100-font-size': '14px',
  '--trellis-typography-body-125-font-family': 'Lexend Deca',
  '--trellis-typography-body-125-font-weight': '600',
  '--trellis-typography-body-125-font-size': '14px',
  '--trellis-typography-body-125-line-height': '24px',
  '--trellis-typography-body-125-letter-spacing': '0px',
  '--trellis-typography-body-200-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-200-font-weight': '300',
  '--trellis-typography-body-200-font-size': '16px',
  '--trellis-typography-body-200-letter-spacing': '0px',
  '--trellis-typography-body-200-line-height': '24px',
  '--trellis-typography-body-300-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-300-font-weight': '400',
  '--trellis-typography-body-300-font-size': '18px',
  '--trellis-typography-body-300-line-height': '24px',
  '--trellis-typography-body-300-letter-spacing': '0px',
  '--trellis-typography-body-400-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-400-font-weight': '400',
  '--trellis-typography-body-400-font-size': '20px',
  '--trellis-typography-body-400-line-height': '28px',
  '--trellis-typography-body-400-letter-spacing': '0px',
  '--trellis-typography-body-500-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-500-font-weight': '400',
  '--trellis-typography-body-500-font-size': '22px',
  '--trellis-typography-body-500-line-height': '28px',
  '--trellis-typography-body-500-letter-spacing': '0px',
  '--trellis-typography-body-600-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-600-font-weight': '400',
  '--trellis-typography-body-600-font-size': '26px',
  '--trellis-typography-body-600-line-height': '32px',
  '--trellis-typography-body-600-letter-spacing': '0px',
  '--trellis-typography-body-700-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-body-700-font-weight': '400',
  '--trellis-typography-body-700-font-size': '28px',
  '--trellis-typography-body-700-line-height': '36px',
  '--trellis-typography-body-700-letter-spacing': '0px',
  '--trellis-typography-detail-100-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-detail-100-font-size': '12px',
  '--trellis-typography-detail-100-line-height': '14px',
  '--trellis-typography-detail-100-letter-spacing': '0px',
  '--trellis-typography-detail-100-font-weight': '300',
  '--trellis-typography-detail-200-font-family': 'Lexend Deca, Helvetica, Arial, sans-serif',
  '--trellis-typography-detail-200-font-weight': '300',
  '--trellis-typography-detail-200-font-size': '12px',
  '--trellis-typography-detail-200-line-height': '18px',
  '--trellis-typography-detail-200-letter-spacing': '0px',
  '--trellis-typography-code-100-font-family': 'Source Code Pro, Consolas, Monaco, Courier New, monospace',
  '--trellis-typography-code-100-font-weight': '500',
  '--trellis-typography-code-100-font-size': '14px',
  '--trellis-typography-code-100-line-height': '24px',
  '--trellis-typography-code-100-letter-spacing': '0px',
  '--trellis-color-text-interactive-default-disabled': '#99ACC2',
  '--trellis-color-icon-secondary-subtle': '#FFFFFF',
  '--trellis-color-fill-tertiary-alt': '#CBD6E2',
  '--trellis-color-fill-accent-light-orange-default': '#FF8F59',
  '--trellis-color-fill-accent-light-orange-hover': '#FFC7AC',
  '--trellis-color-fill-accent-light-orange-pressed': '#E68250',
  '--trellis-color-fill-accent-light-orange-subtle': '#FFF3EE',
  '--trellis-color-fill-accent-neutral-default': '#425B76',
  '--trellis-color-fill-accent-neutral-hover': '#516F90',
  '--trellis-color-fill-accent-neutral-pressed': '#33475B',
  '--trellis-color-fill-accent-neutral-subtle': '#425B76',
  '--trellis-color-text-interactive-on-fill': '#ffffff',
  '--trellis-color-text-alert-hover': '#F8A9AD',
  '--trellis-color-text-alert-pressed': '#D94C53',
  '--trellis-color-text-alert-on-fill': '#ffffff'
};