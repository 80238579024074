'use es6';

import { CacheLayer, cacheGet, cachePut } from './internal/layeredCache';
let funcCache;
let prevFunc;
let prevCacheLayer;
export default function partial(func, ...args) {
  if (func == null) return func;
  if (args.length === 0) return func;
  if (!funcCache) funcCache = new WeakMap();
  let cacheLayer;
  if (func === prevFunc) {
    // Optimize for the case where we're called repeatedly with the same func
    cacheLayer = prevCacheLayer;
  } else {
    cacheLayer = funcCache.get(func);
    if (!cacheLayer) {
      cacheLayer = new CacheLayer();
      funcCache.set(func, cacheLayer);
    }
  }
  prevFunc = func;
  prevCacheLayer = cacheLayer;
  let partialFunc = cacheGet(cacheLayer, args);
  if (!partialFunc) {
    partialFunc = (...partialArgs) => func(...args, ...partialArgs);
    cachePut(cacheLayer, args, partialFunc);
  }
  return partialFunc;
}