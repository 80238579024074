export let HubsTrackingAttr;
(function (HubsTrackingAttr) {
  HubsTrackingAttr["Id"] = "hubs_id";
  HubsTrackingAttr["Content"] = "hubs_content";
  HubsTrackingAttr["ContentCta"] = "hubs_content-cta";
  HubsTrackingAttr["Medium"] = "hubs_medium";
  HubsTrackingAttr["Source"] = "hubs_source";
  HubsTrackingAttr["Campaign"] = "hubs_campaign";
  HubsTrackingAttr["Cta"] = "hubs_cta";
  HubsTrackingAttr["Post"] = "hubs_post";
  HubsTrackingAttr["PostCta"] = "hubs_post-cta";
  HubsTrackingAttr["SignupUrl"] = "hubs_signup-url";
  HubsTrackingAttr["SignupCta"] = "hubs_signup-cta";
})(HubsTrackingAttr || (HubsTrackingAttr = {}));