// We export this separately so teams that need this can create custom sheet
// managers that inject a custom namespace into the plugin.
const namespacePlugin = namespace => {
  function plugin(context, _, selectors) {
    if (namespace && context === 2) {
      const selector = selectors[0];
      if (selector.startsWith('.') && !selector.startsWith(`${namespace} `)) {
        let newSelector = `${namespace} ${selector}`;
        newSelector = newSelector.replaceAll(',', `,${namespace} `);
        selectors[0] = newSelector;
      }
    }
  }

  // Use Object.defineProperty to set the name in a way that survives
  // minification. Styled components needs it's plugins to have a name.
  Object.defineProperty(plugin, 'name', {
    value: `namespacePlugin-${namespace}`
  });
  return plugin;
};
export default namespacePlugin;