export default {
  "zh-cn": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "CN¥"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ",",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n%u",
          "units": {
            "byte": {
              "other": "字节"
            },
            "gb": "吉字节",
            "kb": "千字节",
            "mb": "兆字节",
            "tb": "太字节"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": "、",
            "twoWordsConnector": "和",
            "lastWordConnector": "和"
          },
          "exclusive": {
            "wordsConnector": "、",
            "twoWordsConnector": "或",
            "lastWordConnector": "或"
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "other": "0"
            },
            "10000": {
              "other": "0万"
            },
            "100000": {
              "other": "00万"
            },
            "1000000": {
              "other": "000万"
            },
            "10000000": {
              "other": "0000万"
            },
            "100000000": {
              "other": "0亿"
            },
            "1000000000": {
              "other": "00亿"
            },
            "10000000000": {
              "other": "000亿"
            },
            "100000000000": {
              "other": "0000亿"
            },
            "1000000000000": {
              "other": "0万亿"
            },
            "10000000000000": {
              "other": "00万亿"
            },
            "100000000000000": {
              "other": "000万亿"
            }
          },
          "short": {
            "1000": {
              "other": "0"
            },
            "10000": {
              "other": "0万"
            },
            "100000": {
              "other": "00万"
            },
            "1000000": {
              "other": "000万"
            },
            "10000000": {
              "other": "0000万"
            },
            "100000000": {
              "other": "0亿"
            },
            "1000000000": {
              "other": "00亿"
            },
            "10000000000": {
              "other": "000亿"
            },
            "100000000000": {
              "other": "0000亿"
            },
            "1000000000000": {
              "other": "0万亿"
            },
            "10000000000000": {
              "other": "00万亿"
            },
            "100000000000000": {
              "other": "000万亿"
            }
          }
        }
      }
    }
  }
}