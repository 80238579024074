import { stylePropKeys } from '../styleProps';

/**
 * Separates props passed to a component into the following categories:
 *
 * - Token props: CSS-conformant token properties allowed by the design system.
 * - Other props: Everything else not matching our token properties.
 */
export function filterTokenProps(props) {
  const tokenProps = {};
  const restProps = {};
  Object.keys(props).forEach(key => {
    if (stylePropKeys[key]) {
      tokenProps[key] = props[key];
    } else {
      restProps[key] = props[key];
    }
  });
  return {
    tokenProps,
    restProps
  };
}