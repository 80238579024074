const LEFT_MOUSE_BUTTON = 0;
export const isLeftClick = mouseEvent => {
  const {
    button,
    altKey,
    ctrlKey,
    shiftKey,
    metaKey
  } = mouseEvent;
  return button === LEFT_MOUSE_BUTTON && !(altKey || ctrlKey || shiftKey || metaKey);
};
export const stopPropagationHandler = evt => {
  if (evt) evt.stopPropagation();
};
export const preventDefaultHandler = evt => {
  if (evt) evt.preventDefault();
};

/**
 * @param {KeyboardEvent} evt
 * @returns {boolean} `true` if any modifier (alt, ctrl, meta, or shift) is pressed
 */
export const isModifierPressed = evt => evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey;