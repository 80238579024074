/**
 * Determine the proper tabIndex based on the `disabled` and `tabIndex` props. Note that `tabIndex`
 * should not have a default value; it should only be non-null to override normal tabbing behavior.
 */
export const getTabIndex = (disabled, tabIndex, disabledValue = -1) => {
  if (typeof tabIndex === 'number') return tabIndex;
  if (disabled) {
    if (disabledValue === null) return undefined;
    return disabledValue;
  }
  return 0;
};