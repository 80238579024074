export default {
  "nl": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%u%n",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " en ",
            "lastWordConnector": " en "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " of ",
            "lastWordConnector": " of "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 duizend",
              "other": "0 duizend"
            },
            "10000": {
              "one": "00 duizend",
              "other": "00 duizend"
            },
            "100000": {
              "one": "000 duizend",
              "other": "000 duizend"
            },
            "1000000": {
              "one": "0 miljoen",
              "other": "0 miljoen"
            },
            "10000000": {
              "one": "00 miljoen",
              "other": "00 miljoen"
            },
            "100000000": {
              "one": "000 miljoen",
              "other": "000 miljoen"
            },
            "1000000000": {
              "one": "0 miljard",
              "other": "0 miljard"
            },
            "10000000000": {
              "one": "00 miljard",
              "other": "00 miljard"
            },
            "100000000000": {
              "one": "000 miljard",
              "other": "000 miljard"
            },
            "1000000000000": {
              "one": "0 biljoen",
              "other": "0 biljoen"
            },
            "10000000000000": {
              "one": "00 biljoen",
              "other": "00 biljoen"
            },
            "100000000000000": {
              "one": "000 biljoen",
              "other": "000 biljoen"
            }
          },
          "short": {
            "1000": {
              "one": "0K",
              "other": "0K"
            },
            "10000": {
              "one": "00K",
              "other": "00K"
            },
            "100000": {
              "one": "000K",
              "other": "000K"
            },
            "1000000": {
              "one": "0 mln.",
              "other": "0 mln."
            },
            "10000000": {
              "one": "00 mln.",
              "other": "00 mln."
            },
            "100000000": {
              "one": "000 mln.",
              "other": "000 mln."
            },
            "1000000000": {
              "one": "0 mld.",
              "other": "0 mld."
            },
            "10000000000": {
              "one": "00 mld.",
              "other": "00 mld."
            },
            "100000000000": {
              "one": "000 mld.",
              "other": "000 mld."
            },
            "1000000000000": {
              "one": "0 bln.",
              "other": "0 bln."
            },
            "10000000000000": {
              "one": "00 bln.",
              "other": "00 bln."
            },
            "100000000000000": {
              "one": "000 bln.",
              "other": "000 bln."
            }
          }
        }
      }
    }
  }
}