import { useTheme } from 'foundations-theming/utils';
export const useRingColor = use => {
  const theme = useTheme();
  switch (use) {
    case 'on-dark':
      return 'rgba(255,255,255,0.5)';
    case 'danger':
      return theme.color['fill-accent-red-hover'];
    case 'primary':
    case 'secondary':
      return theme.color['fill-accent-orange-hover'];
    case 'secondary-ghost':
      return 'rgba(0, 0, 0, 0)';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return theme.color['text-core-disabled'];
    case 'success':
      return theme.color['fill-positive-hover'];
    case 'link':
    default:
      return theme.color['fill-secondary-hover'];
  }
};
export const useIndicatorColor = use => {
  const theme = useTheme();
  switch (use) {
    case 'danger':
      return theme.color['fill-alert-default'];
    case 'primary':
    case 'secondary':
      return theme.color['fill-primary-default'];
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return theme.color['fill-accent-neutral-default'];
    case 'success':
      return theme.color['fill-positive-default'];
    case 'on-dark':
    case 'secondary-ghost':
      return theme.color['fill-surface-default'];
    case 'link':
    default:
      return theme.color['fill-info-default'];
  }
};