export let Intent;
(function (Intent) {
  Intent["academyLesson"] = "learningCenter";
  Intent["academyPlaylist"] = "academyPlaylist";
  Intent["academyTrack"] = "academyTrack";
  Intent["academyVideo"] = "academyVideo";
  Intent["community"] = "community";
  Intent["communityStaging"] = "communityStaging";
  Intent["connectIntegration"] = "connectIntegration";
  Intent["conversations"] = "conversations";
  Intent["crmDeals"] = "crmDeals";
  Intent["crmLeadManagement"] = "crmLeadManagement";
  Intent["fbCrm"] = "fbCrm";
  Intent["landingpages"] = "landingpages";
  Intent["leadAds"] = "leadAds";
  Intent["learningCenter"] = "learningCenter";
  Intent["livechatBuilder"] = "livechat-builder";
  Intent["marketingAds"] = "marketingAds";
  Intent["marketingFreeLeadFlows"] = "marketingFreeLeadFlows";
  Intent["marketingFreeForms"] = "marketingFreeForms";
  Intent["marketingFreeEmail"] = "marketingFreeEmail";
  Intent["marketingFreeAnalytics"] = "marketingFreeAnalytics";
  Intent["marketplaceTheme"] = "marketplaceTheme";
  Intent["meetingSchedulerUnboxing"] = "meetingSchedulerUnboxing";
  Intent["oauthAuthorization"] = "oauthAuthorization";
  Intent["purchase"] = "purchase";
  Intent["salesDocuments"] = "salesDocuments";
  Intent["salesFree"] = "salesFree";
  Intent["salesExtension"] = "salesExtension";
  Intent["salesMeetings"] = "salesMeetings";
  Intent["salesOutlook"] = "salesOutlook";
  Intent["salesO365"] = "salesO365";
  Intent["salesPro"] = "salesPro";
  Intent["salesSequences"] = "salesSequences";
  Intent["salesTemplates"] = "salesTemplates";
  Intent["salesVsto"] = "salesVsto";
  Intent["trial"] = "trial";
  Intent["blackAtInbound"] = "blackAtInbound";
  Intent["aiWebsiteBuilder"] = "AIWebsiteBuilder";
  Intent["blog"] = "blog";
})(Intent || (Intent = {}));