export const SUN_CORE_LAYER = '-1';
export const SUN_LAYER = '0';
export const MERCURY_LAYER = '1';
export const VENUS_LAYER = '2';
export const VENUS_TROPOSPHERE_LAYER = '3';
export const VENUS_MESOSPHERE_LAYER = '5';
export const VENUS_THERMOSPHERE_LAYER = '100';
export const VENUS_IONOSPHERE_LAYER = '101';
export const EARTH_LAYER = '1000';
export const MARS_LAYER = '1070';
export const JUPITER_LAYER = '1111';
export const SATURN_LAYER = '1112';
export const URANUS_LAYER = '1211';
export const NEPTUNE_LAYER = '2000';