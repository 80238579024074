const DEPRECIATED_ICON_SIZES = {
  xxs: 16,
  xs: 24,
  sm: 48,
  md: 64,
  lg: 72,
  xl: 96,
  xxl: 128
};
export const ICON_SIZES = Object.assign({
  small: 16,
  medium: 32,
  large: 48
}, DEPRECIATED_ICON_SIZES);
export const CARET_WIDTH = 11.5;
export const CARET_HEIGHT = 21.1;