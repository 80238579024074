// This file is auto generated by bin/create-constants.js
'use es6';

export default {
  chain: 'link',
  'comment-o': 'comments',
  'comments-o': 'comments',
  contact: 'contacts',
  copy: 'duplicate',
  'external-link': 'externalLink',
  eye: 'view',
  pencil: 'edit',
  photo: 'insertImage',
  'upload-light': 'upload',
  'warning-triangle': 'warning'
};