export const ALERT_TYPE_INFO = 'info';
export const ALERT_TYPE_WARNING = 'warning';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_DANGER = 'danger';
export const ALERT_TYPE_TIP = 'tip';
export const ALERT_TYPES = {
  [ALERT_TYPE_INFO]: 'alert-info private-alert--info',
  [ALERT_TYPE_WARNING]: 'alert-warning private-alert--warning',
  [ALERT_TYPE_SUCCESS]: 'alert-success private-alert--success',
  [ALERT_TYPE_DANGER]: 'alert-danger private-alert--danger',
  [ALERT_TYPE_TIP]: 'private-alert--tip'
};
export const ALERT_USE_DEFAULT = 'default';
export const ALERT_USE_INLINE = 'inline';
export const ALERT_USES = {
  [ALERT_USE_DEFAULT]: '',
  [ALERT_USE_INLINE]: 'private-alert--inline'
};