const tshirtShorthandMap = {
  'extra-small': 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  'extra-large': 'xl'
};

/**
 * @param {string} size
 * @returns {string} The shorthand size corresponding to the given longhand size, or `size` itself.
 */
export const toShorthandSize = size => tshirtShorthandMap[size] || size;