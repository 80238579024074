/**
 * The lazyEval function and the associated createLazyPropType function are needed
 * specifically for class components due to the way defaultProps work.
 * Function components, unlike class components, can use the newer object destructuring
 * syntax in the function signature to indicate default values for props.
 * The lazyEval function is similar to _.result, but with support for the single-arg case.
 * For example, `result(foo)` gives you `typeof foo === 'function' ? foo() : foo`.
 */
export default function lazyEval(object, property) {
  const subject = property ? Object.prototype.hasOwnProperty.call(object, property) : object;
  return typeof subject === 'function' ? subject() : subject;
}