import { useTheme } from 'foundations-theming/utils';
export const useMargin = (use, flush) => {
  const theme = useTheme();
  const defaultStyles = {
    '&&': {
      marginBottom: flush ? theme.space[0] : undefined
    },
    '& > :last-child': {
      marginBottom: theme.space[0]
    }
  };
  if (use === 'default') {
    return Object.assign({
      marginTop: theme.space[0],
      marginBottom: theme.space[400]
    }, defaultStyles);
  }
  return Object.assign({
    marginTop: theme.space[1000],
    marginBottom: theme.space[1000]
  }, defaultStyles);
};
export const useHeight = value => {
  const theme = useTheme();
  if (typeof value === 'number') {
    return `${value}px`;
  } else if (typeof value === 'string' && value.includes('px')) {
    return value;
  } else if (typeof value === 'string' && value.includes('%')) {
    return value;
  } else if (theme.space[value] !== undefined) {
    return theme.space[value];
  } else {
    return undefined;
  }
};