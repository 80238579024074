import hubspot from 'hubspot';
import performance from '../vendor/performance';
export const getHubHttpData = finishedTimestamp => {
  if (typeof hubspot.getAllHttpRequestStats === 'function' && typeof performance.now === 'function') {
    const byStatus = status => ({
      state,
      started,
      finished
    }) => started < finishedTimestamp && (status === 'pending' ? state === 'pending' || finished > finishedTimestamp : state === status && finished <= finishedTimestamp);
    const MAX_URLS = 10;
    const toUrls = arr => arr.slice(0, MAX_URLS).map(r => r.url).join(',');
    const requests = hubspot.getAllHttpRequestStats();
    const succeededRequests = requests.filter(byStatus('succeeded'));
    const failedRequests = requests.filter(byStatus('failed'));
    const abortedRequests = requests.filter(byStatus('aborted'));
    const pendingRequests = requests.filter(byStatus('pending'));
    const timedOutRequests = requests.filter(byStatus('timedOut'));
    const failedRequestsMinus404AndRetries = failedRequests.filter(r => r.status !== 404 && !r.willBeRetried);
    const notFoundRequests = failedRequests.filter(r => r.status === 404);
    return {
      numSucceededRequests: succeededRequests.length,
      numAbortedRequests: abortedRequests.length,
      numPendingRequests: pendingRequests.length,
      numNotFound: notFoundRequests.length,
      numTimedoutRequests: timedOutRequests.length,
      numFailedRequestsMinus404AndRetries: failedRequestsMinus404AndRetries.length,
      numRetriedFailures: failedRequests.filter(r => !!r.willBeRetried).length,
      failedRequestUrls: toUrls(failedRequestsMinus404AndRetries),
      timedOutRequestUrls: toUrls(timedOutRequests),
      pendingRequestUrls: toUrls(pendingRequests),
      notFoundUrls: toUrls(notFoundRequests)
    };
  }
  return null;
};