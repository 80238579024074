import { FOCUSABLE as FOCUSABLE_SELECTOR } from '../constants/Selectors';
import { rotateAround } from './Arrays';
import { translateRect } from './Rects';
import { first, last } from './underscore';

// Polyfill for el.classList.contains(className) that doesn't fail for SVGs in IE11
export const elementHasClass = (el, className) => {
  return !!(el && el.classList && el.classList.contains(className));
};

// Polyfill for el.matches(selector)
export const matches = (el, selector) => {
  if (!el) {
    return false;
  }
  if (el.matches) {
    return el.matches(selector);
  }
  if (el.msMatchesSelector) {
    return el.msMatchesSelector(selector);
  }
  if (el.parentElement) {
    const matchingSiblings = el.parentElement.querySelectorAll(selector);
    for (let i = 0; i < matchingSiblings.length; i++) {
      if (matchingSiblings[i] === el) {
        return true;
      }
    }
  }
  return false;
};

// Polyfill for el.closest(selector)
export const closest = (el, selector) => {
  if (!el) {
    return null;
  }
  if (el.closest) {
    return el.closest(selector);
  }
  let currentEl = el;
  do {
    if (matches(currentEl, selector)) {
      return currentEl;
    }
    currentEl = currentEl.parentElement;
  } while (currentEl);
  return null;
};

// Is el within the bounds of the given screen?
export const elementIsOnScreen = (el, screen = window) => {
  const elementBounds = el.getBoundingClientRect();
  const xWithinBounds = elementBounds.right >= 0 && elementBounds.left <= screen.innerWidth;
  const yWithinBounds = elementBounds.bottom >= 0 && elementBounds.top <= screen.innerHeight;
  return xWithinBounds && yWithinBounds;
};

// Is el visible (for tabbing purposes)?
const elementIsVisible = el => {
  const computedStyles = getComputedStyle(el, null);

  // Patch for Firefox < 62: https://bugzilla.mozilla.org/show_bug.cgi?id=1467722
  if (!computedStyles) return true;
  return computedStyles.visibility !== 'hidden';
};

// Returns an array containing every tabbable element within the given container. The array order
// reflects the tabbing order, unless positive tabIndex values (e.g. tabIndex={7}) are used.
// Such tabIndex values are exceedingly rare in modern web apps, so don't worry about it.
export const getTabbableElements = container => {
  return Array.from(container.querySelectorAll(FOCUSABLE_SELECTOR)).filter(el => {
    // Hidden elements aren't tabbable. Sadly, no CSS query can exclude such elements.
    return elementIsVisible(el);
  });
};

// Returns the next element after the given element (and its descendants) in the document tab order.
export const getNextTabbableElementExclusive = startEl => {
  const allElements = Array.from(document.querySelectorAll('*'));
  for (let i = allElements.indexOf(startEl) + 1; i < allElements.length; i++) {
    const el = allElements[i];
    if (startEl.contains(el)) {
      continue;
    }
    if (matches(el, FOCUSABLE_SELECTOR) && elementIsVisible(el)) {
      return el;
    }
  }
  return null;
};

// Returns the previous element in the document tab order, *including* the given element and its
// descendants. This is equivalent to what the browser would focus if the user pressed Shift+Tab
// from the nextSibling of the given element, for example.
export const getPrevTabbableElementInclusive = startEl => {
  const focusableDescendants = getTabbableElements(startEl);
  if (focusableDescendants.length) {
    return last(focusableDescendants);
  }
  if (matches(startEl, FOCUSABLE_SELECTOR)) {
    return startEl;
  }
  const allElements = Array.from(document.querySelectorAll('*'));
  for (let i = allElements.indexOf(startEl) - 1; i >= 0; i--) {
    const el = allElements[i];
    if (matches(el, FOCUSABLE_SELECTOR) && elementIsVisible(el)) {
      return el;
    }
  }
  return null;
};

/**
 * Handles a `keydown` event so that, if the user pressed Tab, the `insertedElement` is treated as
 * if it comes after the `targetElement` in the tab order.
 */
export const handleTabWithOrdering = (evt, targetElement, insertedElement) => {
  if (evt.key === 'Tab') {
    const reverseTab = evt.shiftKey;
    // Popups are appended to <body>, which means they live at the end of the document tabbing order,
    // regardless of their visual placement. So when the user presses Tab and it would take them out
    // of the Tether, they should tab to an element adjacent to the popup target.

    // Ignore key events that have already had their behavior overridden (e.g. by TinyMCE)
    if (evt.defaultPrevented) return;
    const activeElement = document.activeElement;
    let overrideFocusEl;
    if (insertedElement.contains(activeElement)) {
      const tabbableElsWithinPopup = getTabbableElements(insertedElement);
      if (!reverseTab && activeElement === last(tabbableElsWithinPopup)) {
        // Tab out of the popup to the tabbable element after the target (exclusive).
        overrideFocusEl = getNextTabbableElementExclusive(targetElement);
      } else if (reverseTab && activeElement === first(tabbableElsWithinPopup)) {
        // Tab out of the popup to the tabbable element before the target (inclusive).
        overrideFocusEl = getPrevTabbableElementInclusive(targetElement);
      }
    } else if (!reverseTab && activeElement === getPrevTabbableElementInclusive(targetElement)) {
      overrideFocusEl = first(getTabbableElements(insertedElement));
    } else if (reverseTab && activeElement === getNextTabbableElementExclusive(targetElement)) {
      overrideFocusEl = last(getTabbableElements(insertedElement));
    }
    if (overrideFocusEl) {
      overrideFocusEl.focus();
      // We prevent the default tab behavior *only* if we succeeded in overriding the focus. This
      // is an escape hatch for any edge cases where overrideFocusEl isn't actually focusable. (The
      // rules of focusability are mercurial and may vary across systems/browsers.)
      if (document.activeElement === overrideFocusEl) {
        evt.preventDefault();
      }
    }
  }
};

/**
 * Goes through all the elements matching `selector` within `container` starting from the one that
 * currently has the focus (or from the start), then moves the focus into the next one that can be
 * focused (or, if `reverse = true`, the previous one).
 *
 * @param {string} selector
 * @param {HTMLElement} container
 * @param {boolean} reverse
 * @return {?HTMLElement} The element that received the focus
 */
export const moveFocusToNext = (selector, container, reverse = false) => {
  const allMatches = Array.from(container.querySelectorAll(selector));
  if (allMatches.length === 0) return null;

  // Get an ordered list of matching elements don't already contain the focus
  const currentMenuItem = closest(document.activeElement, selector);
  let candidateMatches = rotateAround(allMatches, currentMenuItem);
  candidateMatches = candidateMatches.filter(menuItem => !menuItem.contains(document.activeElement));

  // If reverse is set, flip the order so we move backward in the DOM
  if (reverse) candidateMatches.reverse();

  // Move the focus to the first focusable element found within a candidate element
  for (let i = 0; i < candidateMatches.length; i++) {
    const focusTarget = candidateMatches[i].querySelector(FOCUSABLE_SELECTOR);
    if (focusTarget) {
      focusTarget.focus();
      return focusTarget;
    }
  }
  return null;
};

/**
 * Check if `descendantEl` is in a popover attached to `containerEl` (or a popover attached to a
 * popover attached to `containerEl`, or a popover attached to a popover attached to a popover...).
 *
 * @param {HTMLElement} containerEl
 * @param {HTMLElement} descendantEl
 * @return {boolean}
 */
export const isInPopoversAttachedTo = (containerEl, descendantEl) => {
  const popoverEls = Array.from(containerEl.querySelectorAll('[data-popover-id]'));
  return popoverEls.some(targetEl => {
    const popoverIds = targetEl.getAttribute('data-popover-id').split(' ');
    return popoverIds.some(popoverId => {
      const popoverEl = descendantEl.ownerDocument.getElementById(popoverId);
      if (popoverEl) {
        if (popoverEl.contains(descendantEl)) return true;
        return isInPopoversAttachedTo(popoverEl, descendantEl);
      }
      return false;
    });
  });
};
const getAttrAsList = (element, attrKey) => element.hasAttribute(attrKey) ? element.getAttribute(attrKey).split(' ') : [];

/**
 * Add a single value to a DOM attribute that contains a list of space-separated values.
 */
export const addToListAttr = (element, attrKey, newValue) => {
  if (!element) return;
  const attrAsArray = getAttrAsList(element, attrKey);
  if (attrAsArray.includes(newValue)) return;
  element.setAttribute(attrKey, attrAsArray.concat(newValue).join(' '));
};

/**
 * Remove a single value from a DOM attribute that contains a list of space-separated values.
 */
export const removeFromListAttr = (element, attrKey, value) => {
  if (!element) return;
  const attrAsArray = getAttrAsList(element, attrKey);
  if (!attrAsArray.includes(value)) return;
  attrAsArray.splice(attrAsArray.indexOf(value), 1);
  if (attrAsArray.length === 0) {
    element.removeAttribute(attrKey);
  } else {
    element.setAttribute(attrKey, attrAsArray.join(' '));
  }
};

/**
 * @param {?string} attr
 * @param {string} value
 * @returns {boolean} True if the space-separated list attr includes the given value
 */
export const listAttrIncludes = (attr, value) => (attr || '').split(' ').includes(value);
// Invoke the `callback` every `frequency` ms until `element` fires one of the `events`.
export const repeatUntilEventOnElement = (callback, frequency, element, events) => {
  const handle = {
    active: true,
    count: 0,
    stop
  };
  const timer = setInterval(() => {
    callback();
    handle.count += 1;
  }, frequency);
  function stop() {
    handle.active = false;
    clearInterval(timer);
    events.forEach(event => {
      element.removeEventListener(event, stop);
    });
  }
  events.forEach(event => {
    element.addEventListener(event, stop);
  });
  return handle;
};

/**
 * @param {HTMLElement} element
 * @return {Array} Every `window` containing this element, including any surrounding `<iframe>` (browser security model allowing)
 */
export const getIframeAwareSurroundingWindows = element => {
  const results = [];
  let ancestorElement = element;
  while (ancestorElement) {
    const ancestorElementWindow = ancestorElement.ownerDocument.defaultView;
    results.push(ancestorElementWindow);
    try {
      ancestorElement = ancestorElementWindow.frameElement;
    } catch (e) {
      // IE11
      ancestorElement = null;
    }
  }
  return results;
};
const getIframeAwareAncestorElement = element => {
  try {
    return element.parentElement || element.ownerDocument.defaultView.frameElement;
  } catch (e) {
    // IE11
    return null;
  }
};

/**
 * @param {HTMLElement} element
 * @return {Array} Every ancestor of this element, including ancestors of any surrounding `<iframe>` (browser security model allowing)
 */
export const getIframeAwareAncestorElements = element => {
  const results = [];
  let ancestorElement = getIframeAwareAncestorElement(element);
  while (ancestorElement) {
    results.push(ancestorElement);
    ancestorElement = getIframeAwareAncestorElement(ancestorElement);
  }
  return results;
};
export const getIframeAwareClientRect = element => {
  const elementRect = element.getBoundingClientRect();
  try {
    const iframe = element.ownerDocument.defaultView.frameElement;
    if (document === element.ownerDocument || !iframe) return elementRect;
    const iframeRect = getIframeAwareClientRect(iframe);
    return translateRect(elementRect, iframeRect.left, iframeRect.top);
  } catch (e) {
    // IE11
  }
  return elementRect;
};

/**
 * @param {HTMLElement} element
 * @param {?HTMLElement} container Another element, or `null` to get document-relative coordinates
 * @return {object} A rect that gives the position of `element` relative to `container`
 */
export const getRelativeRect = (element, container) => {
  const elementRect = getIframeAwareClientRect(element);
  let xOrigin;
  let yOrigin;
  if (container && container.offsetParent) {
    const containerRect = getIframeAwareClientRect(container);
    xOrigin = containerRect.left;
    yOrigin = containerRect.top;
  } else {
    const {
      scrollLeft,
      scrollTop
    } = document.documentElement;
    xOrigin = scrollLeft;
    yOrigin = scrollTop;
  }
  return translateRect(elementRect, xOrigin, yOrigin);
};