import { isIntrospectionEnabled } from './internal/introspection';
let notRequiredCache;
let hiddenCache;

/**
 * @package
 * Implementations for decorators are exported in a mutable record
 * so they can be overwritten at runtime for type generation.
 * The `rawDecorators` object should not be used directly.
 */
export const rawDecorators = {
  hidden: propType => {
    if (propType == null) return propType;
    hiddenCache = hiddenCache || new WeakMap();
    if (!hiddenCache.has(propType)) {
      const hiddenPropType = (...args) => propType(...args);
      hiddenPropType.getType = propType.getType;
      if (propType.__INTROSPECTION__) {
        hiddenPropType.__INTROSPECTION__ = Object.assign({}, propType.__INTROSPECTION__, {
          isHidden: true
        });
      } else if (isIntrospectionEnabled()) {
        hiddenPropType.__INTROSPECTION__ = {
          args: {},
          isHidden: true
        };
      }
      hiddenCache.set(propType, hiddenPropType);
    }
    return hiddenCache.get(propType);
  },
  notRequired: propType => {
    if (propType == null) return propType;
    notRequiredCache = notRequiredCache || new WeakMap();
    if (!notRequiredCache.has(propType)) {
      const notRequiredPropType = (props, propName, ...rest) => {
        if (props[propName] == null) return null;
        return propType(props, propName, ...rest);
      };
      notRequiredPropType.getType = propType.getType;
      if (propType.__INTROSPECTION__) {
        notRequiredPropType.__INTROSPECTION__ = Object.assign({}, propType.__INTROSPECTION__, {
          isRequired: false
        });
      }
      notRequiredCache.set(propType, notRequiredPropType);
    }
    return notRequiredCache.get(propType);
  }
};
export const notRequired = propType => rawDecorators.notRequired(propType);
export const hidden = propTypes => rawDecorators.hidden(propTypes);