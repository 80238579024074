'use es6';

import { Record } from 'immutable';
import I18n from 'I18n';
import { ProcessingConsentTypes, LegalConsentKeys, LegalConsentOptions, EditableLegalConsentKeys, SettingsKeys } from '../constants/LegalConsent';
import isEmpty from 'hs-lodash/isEmpty';
import isEqual from 'hs-lodash/isEqual';
import findIndex from 'hs-lodash/findIndex';
const keyedText = (key, options) => I18n.text(`gdpr.${key}`, options);
const DEFAULT_LEGITIMATE_INTEREST_LEGAL_BASIS = 'LEGITIMATE_INTEREST_PQL';
const DEFAULT_OPTIONS = {
  checkboxOptions: []
};
export function getEditableLegalConsentKeysForLegalConsentType(legalConsentType) {
  switch (legalConsentType) {
    case LegalConsentOptions.IMPLICIT_CONSENT:
      return [EditableLegalConsentKeys.COMMUNICATION_CONSENT_TEXT, EditableLegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES, EditableLegalConsentKeys.PRIVACY_POLICY_TEXT, EditableLegalConsentKeys.PROCESSING_CONSENT_TEXT];
    case LegalConsentOptions.SEPERATE_CONSENT:
      return [EditableLegalConsentKeys.COMMUNICATION_CONSENT_TEXT, EditableLegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES, EditableLegalConsentKeys.PROCESSING_CONSENT_TEXT, EditableLegalConsentKeys.PROCESSING_CONSENT_CHECKBOX_LABEL, EditableLegalConsentKeys.PROCESSING_CONSENT_FOOTER_TEXT, EditableLegalConsentKeys.PRIVACY_POLICY_TEXT];
    case LegalConsentOptions.LEGITIMATE_INTEREST:
      return [EditableLegalConsentKeys.SUBSCRIPTION_TYPES, EditableLegalConsentKeys.LAWFUL_BASIS, EditableLegalConsentKeys.PRIVACY_POLICY_TEXT];
    default:
      return [];
  }
}
export default class LegalConsent extends Record({
  [LegalConsentKeys.IS_LEGITIMATE_INTEREST]: false,
  [LegalConsentKeys.COMMUNICATION_CONSENT_TEXT]: '',
  [LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES]: [],
  [LegalConsentKeys.PROCESSING_CONSENT_TYPE]: ProcessingConsentTypes.IMPLICIT,
  [LegalConsentKeys.PROCESSING_CONSENT_TEXT]: '',
  [LegalConsentKeys.PROCESSING_CONSENT_CHECKBOX_LABEL]: '',
  [LegalConsentKeys.PROCESSING_CONSENT_FOOTER_TEXT]: '',
  [LegalConsentKeys.PRIVACY_POLICY_TEXT]: '',
  [LegalConsentKeys.SUBSCRIPTION_TYPES]: [],
  [LegalConsentKeys.LAWFUL_BASIS]: DEFAULT_LEGITIMATE_INTEREST_LEGAL_BASIS
}) {
  static createLegalConsentFromOption(option = LegalConsentOptions.IMPLICIT_CONSENT, otherOptions = DEFAULT_OPTIONS) {
    const {
      checkboxOptions,
      domain: companyName
    } = otherOptions;
    if (option === LegalConsentOptions.NONE) {
      return null;
    }
    const proccessingConsentType = option === LegalConsentOptions.SEPERATE_CONSENT ? ProcessingConsentTypes.REQUIRED_CHECKBOX : ProcessingConsentTypes.IMPLICIT;
    const isLegitimateInterest = option === LegalConsentOptions.LEGITIMATE_INTEREST;
    const processingConsentTextKey = option === LegalConsentOptions.IMPLICIT_CONSENT ? 'implicitProcessingConsentText' : 'explicitProcessingConsentText';
    const privacyPolicyKey = isLegitimateInterest ? 'legitimateInterestPrivacyPolicyText' : 'privacyPolicyText';
    const options = {
      [LegalConsentKeys.IS_LEGITIMATE_INTEREST]: isLegitimateInterest,
      [LegalConsentKeys.COMMUNICATION_CONSENT_TEXT]: otherOptions[SettingsKeys.COMMUNICATION_CONSENT_TEXT] || keyedText('communicationConsentText', {
        companyName
      }),
      [LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES]: checkboxOptions,
      [LegalConsentKeys.PROCESSING_CONSENT_TYPE]: proccessingConsentType,
      [LegalConsentKeys.PROCESSING_CONSENT_TEXT]: otherOptions[processingConsentTextKey] || keyedText(processingConsentTextKey, {
        companyName
      }),
      [LegalConsentKeys.PROCESSING_CONSENT_CHECKBOX_LABEL]: otherOptions[SettingsKeys.EXPLICIT_PROCESSING_CONSENT_CHECKBOX_LABEL] || keyedText('explicitProcessingConsentCheckboxLabel', {
        companyName
      }),
      [LegalConsentKeys.PRIVACY_POLICY_TEXT]: otherOptions[privacyPolicyKey] || keyedText(privacyPolicyKey, {
        companyName
      })
    };
    if (isLegitimateInterest && !isEmpty(checkboxOptions)) {
      options[LegalConsentKeys.SUBSCRIPTION_TYPES] = [checkboxOptions[0].communicationTypeId];
    }
    return new LegalConsent(options);
  }
  addOrUpdateCommunicationCheckbox(newCheckbox, oldCheckbox = {}) {
    const currentCheckboxes = this.get(LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES);
    let updatedCheckboxes = currentCheckboxes;
    if (!isEmpty(oldCheckbox)) {
      const updateIndex = findIndex(currentCheckboxes, {
        communicationTypeId: oldCheckbox.communicationTypeId
      });
      updatedCheckboxes = currentCheckboxes.map((value, index) => {
        if (index === updateIndex) {
          return newCheckbox;
        }
        return value;
      });
    } else {
      updatedCheckboxes = currentCheckboxes.concat([newCheckbox]);
    }
    return this.set(LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES, updatedCheckboxes);
  }
  removeCheckbox(removedCheckbox) {
    const currentCheckboxes = this.get(LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES);
    return this.set(LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES, currentCheckboxes.filter(({
      communicationTypeId
    }) => !isEqual(removedCheckbox.communicationTypeId, communicationTypeId)));
  }
  getLegalConsentType() {
    const isLegitimateInterest = this.get(LegalConsentKeys.IS_LEGITIMATE_INTEREST);
    const processingConsentType = this.get(LegalConsentKeys.PROCESSING_CONSENT_TYPE);
    if (!isLegitimateInterest && processingConsentType === ProcessingConsentTypes.IMPLICIT) {
      return LegalConsentOptions.IMPLICIT_CONSENT;
    } else if (!isLegitimateInterest && processingConsentType === ProcessingConsentTypes.REQUIRED_CHECKBOX) {
      return LegalConsentOptions.SEPERATE_CONSENT;
    } else if (isLegitimateInterest) {
      return LegalConsentOptions.LEGITIMATE_INTEREST;
    }
    return LegalConsentOptions.NONE;
  }

  // Overite method to prevent arrays being cast to immutables
  merge(record = new LegalConsent()) {
    const updatedRecord = Object.values(EditableLegalConsentKeys || {}).reduce((memo, key) => {
      if (isEmpty(record.get(key)) || isEqual(key, LegalConsentKeys.PRIVACY_POLICY_TEXT) || isEqual(key, LegalConsentKeys.PROCESSING_CONSENT_TEXT)) {
        return memo;
      }
      return memo.set(key, record.get(key));
    }, this);
    return updatedRecord;
  }
}