export default {
  "es-mx": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ",",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " y ",
            "lastWordConnector": " y "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " o ",
            "lastWordConnector": ", o "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 mil",
              "other": "0 mil"
            },
            "10000": {
              "one": "00 mil",
              "other": "00 mil"
            },
            "100000": {
              "one": "000 mil",
              "other": "000 mil"
            },
            "1000000": {
              "one": "0 millón",
              "other": "0 millones"
            },
            "10000000": {
              "one": "00 millones",
              "other": "00 millones"
            },
            "100000000": {
              "one": "000 millones",
              "other": "000 millones"
            },
            "1000000000": {
              "one": "0 mil millones",
              "other": "0 mil millones"
            },
            "10000000000": {
              "one": "00 mil millones",
              "other": "00 mil millones"
            },
            "100000000000": {
              "one": "000 mil millones",
              "other": "000 mil millones"
            },
            "1000000000000": {
              "one": "0 billón",
              "other": "0 billones"
            },
            "10000000000000": {
              "one": "00 billones",
              "other": "00 billones"
            },
            "100000000000000": {
              "one": "000 billones",
              "other": "000 billones"
            }
          },
          "short": {
            "1000": {
              "one": "0K",
              "other": "0K"
            },
            "10000": {
              "one": "00K",
              "other": "00K"
            },
            "100000": {
              "one": "000K",
              "other": "000K"
            },
            "1000000": {
              "one": "0M",
              "other": "0M"
            },
            "10000000": {
              "one": "00M",
              "other": "00M"
            },
            "100000000": {
              "one": "000M",
              "other": "000M"
            },
            "1000000000": {
              "one": "0000M",
              "other": "0000M"
            },
            "10000000000": {
              "one": "00MRD",
              "other": "00MRD"
            },
            "100000000000": {
              "one": "000MRD",
              "other": "000MRD"
            },
            "1000000000000": {
              "one": "0B",
              "other": "0B"
            },
            "10000000000000": {
              "one": "00B",
              "other": "00B"
            },
            "100000000000000": {
              "one": "000B",
              "other": "000B"
            }
          }
        }
      }
    }
  }
}