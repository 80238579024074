import keyMirror from 'react-utils/keyMirror';
export const LEGAL_CONSENT_OPTIONS_KEY = 'legalConsentOptions';
export const LegalConsentOptions = keyMirror({
  NONE: null,
  IMPLICIT_CONSENT: null,
  SEPERATE_CONSENT: null,
  LEGITIMATE_INTEREST: null
});
export const CamelCaseOptions = Object.freeze({
  [LegalConsentOptions.NONE]: 'none',
  [LegalConsentOptions.IMPLICIT_CONSENT]: 'implicitConsent',
  [LegalConsentOptions.SEPERATE_CONSENT]: 'seperateConsent',
  [LegalConsentOptions.LEGITIMATE_INTEREST]: 'legitimateInterest'
});
export const ProcessingConsentTypes = keyMirror({
  IMPLICIT: null,
  REQUIRED_CHECKBOX: null
});
export const LegalConsentKeys = Object.freeze({
  IS_LEGITIMATE_INTEREST: 'isLegitimateInterest',
  COMMUNICATION_CONSENT_TEXT: 'communicationConsentText',
  COMMUNICATION_CONSENT_CHECKBOXES: 'communicationConsentCheckboxes',
  PROCESSING_CONSENT_TYPE: 'processingConsentType',
  PROCESSING_CONSENT_TEXT: 'processingConsentText',
  PROCESSING_CONSENT_CHECKBOX_LABEL: 'processingConsentCheckboxLabel',
  PROCESSING_CONSENT_FOOTER_TEXT: 'processingConsentFooterText',
  PRIVACY_POLICY_TEXT: 'privacyPolicyText',
  SUBSCRIPTION_TYPES: 'legitimateInterestSubscriptionTypes',
  LAWFUL_BASIS: 'legitimateInterestLegalBasis'
});
export const SettingsKeys = Object.freeze({
  COMMUNICATION_CONSENT_CHECKBOXES: 'communicationConsentCheckboxes',
  COMMUNICATION_CONSENT_TEXT: 'communicationConsentText',
  EXPLICIT_PROCESSING_CONSENT_CHECKBOX_LABEL: 'explicitProcessingConsentCheckboxLabel',
  EXPLICIT_PROCESSING_CONSENT_TEXT: 'explicitProcessingConsentText',
  IMPLICIT_PROCESSING_CONSENT_TEXT: 'implicitProcessingConsentText',
  LEGITIMATE_INTEREST_PRIVACY_POLICY_TEXT: 'legitimateInterestPrivacyPolicyText',
  PRIVACY_POLICY_TEXT: 'privacyPolicyText'
});
export const EditableLegalConsentKeys = Object.freeze({
  COMMUNICATION_CONSENT_TEXT: LegalConsentKeys.COMMUNICATION_CONSENT_TEXT,
  COMMUNICATION_CONSENT_CHECKBOXES: LegalConsentKeys.COMMUNICATION_CONSENT_CHECKBOXES,
  PROCESSING_CONSENT_TEXT: LegalConsentKeys.PROCESSING_CONSENT_TEXT,
  PROCESSING_CONSENT_CHECKBOX_LABEL: LegalConsentKeys.PROCESSING_CONSENT_CHECKBOX_LABEL,
  PROCESSING_CONSENT_FOOTER_TEXT: LegalConsentKeys.PROCESSING_CONSENT_FOOTER_TEXT,
  SUBSCRIPTION_TYPES: LegalConsentKeys.SUBSCRIPTION_TYPES,
  LAWFUL_BASIS: LegalConsentKeys.LAWFUL_BASIS,
  PRIVACY_POLICY_TEXT: LegalConsentKeys.PRIVACY_POLICY_TEXT
});
export const ValidationErrors = Object.freeze({
  REQUIRED: 'required'
});