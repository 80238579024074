import { useCallback, useState } from 'react';
export function useHovered({
  hovered,
  onMouseEnter,
  onMouseLeave,
  onMouseMove
}) {
  const [hoveredState, setHoveredState] = useState(false);
  const handleMouseEnter = useCallback(evt => {
    if (onMouseEnter) onMouseEnter(evt);
    setHoveredState(true);
  }, [onMouseEnter]);
  const handleMouseLeave = useCallback(evt => {
    if (onMouseLeave) onMouseLeave(evt);
    setHoveredState(false);
  }, [onMouseLeave]);

  // Why listen for `mousemove`? See: https://git.hubteam.com/HubSpot/UIComponents/issues/3927
  const handleMouseMove = useCallback(evt => {
    if (onMouseMove) onMouseMove(evt);
    setHoveredState(true);
  }, [onMouseMove]);
  return {
    hovered: hovered != null ? hovered : hoveredState,
    onMouseEnter: hoveredState ? onMouseEnter : handleMouseEnter,
    onMouseLeave: hoveredState ? handleMouseLeave : onMouseLeave,
    onMouseMove: hoveredState ? onMouseMove : handleMouseMove
  };
}
export const HoverProvider = props => {
  return props.children(useHovered(props));
};
HoverProvider.displayName = 'HoverProvider';