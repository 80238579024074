export default {
  "no-no": {
    "number": {
      "format": {
        "delimiter": ".",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%u%n",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false
        }
      },
      "percentage": {
        "format": {
          "delimiter": ".",
          "format": "%n %",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "kB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " og ",
            "lastWordConnector": " og "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " eller ",
            "lastWordConnector": " eller "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 tusen",
              "other": "0 tusen"
            },
            "10000": {
              "one": "00 tusen",
              "other": "00 tusen"
            },
            "100000": {
              "one": "000 tusen",
              "other": "000 tusen"
            },
            "1000000": {
              "one": "0 million",
              "other": "0 millioner"
            },
            "10000000": {
              "one": "00 million",
              "other": "00 millioner"
            },
            "100000000": {
              "one": "000 million",
              "other": "000 millioner"
            },
            "1000000000": {
              "one": "0 milliard",
              "other": "0 milliarder"
            },
            "10000000000": {
              "one": "00 milliard",
              "other": "00 milliarder"
            },
            "100000000000": {
              "one": "000 milliard",
              "other": "000 milliarder"
            },
            "1000000000000": {
              "one": "0 trillion",
              "other": "0 trillioner"
            },
            "10000000000000": {
              "one": "00 trillion",
              "other": "00 trillioner"
            },
            "100000000000000": {
              "one": "000 trillion",
              "other": "000 trillioner"
            }
          },
          "short": {
            "1000": {
              "one": "0K",
              "other": "0K"
            },
            "10000": {
              "one": "00K",
              "other": "00K"
            },
            "100000": {
              "one": "000K",
              "other": "000K"
            },
            "1000000": {
              "one": "0M",
              "other": "0M"
            },
            "10000000": {
              "one": "00M",
              "other": "00M"
            },
            "100000000": {
              "one": "000M",
              "other": "000M"
            },
            "1000000000": {
              "one": "0B",
              "other": "0B"
            },
            "10000000000": {
              "one": "00B",
              "other": "00B"
            },
            "100000000000": {
              "one": "000B",
              "other": "000B"
            },
            "1000000000000": {
              "one": "0T",
              "other": "0T"
            },
            "10000000000000": {
              "one": "00T",
              "other": "00T"
            },
            "100000000000000": {
              "one": "000T",
              "other": "000T"
            }
          }
        }
      }
    }
  }
}