import invariant from 'react-utils/invariant';
import CartographerReporter, { CartographerEndpoint } from './CartographerReporter';
import { onVisibilityChange, visibilityState } from '../visibility';
import { PageLoadMetrics } from '../Metrics';
import { isReload, performanceGetEntriesByType } from '../navigation';
import { getHubHttpData } from '../httpRequestsStats';
import { getIsAiCopilotEnabled } from '../aiCopilot';
import Raven from 'raven-js';
import { cleanseRoute } from '../cleanseRoute';
import { isPrerendering } from '../env';
const STATIC_DOMAIN_REGEX = /https:\/\/(static|local).hsappstatic.net\//;

// If the duration of the request is <= 10ms mark the request as a cache hit.
const CACHE_DURATION_MS = 10;
export default class ReaganCompatReporter extends CartographerReporter {
  constructor(options) {
    super(options);
    this.abandonedTimes = [];
    this.lastAbandonedTimestamp = null;
    this.finished = false;
    this.wasHidden = visibilityState() === 'hidden';
    this.prerendering = isPrerendering();
    this.setCustomAttribute('currentVisibility', visibilityState());
    this.setCustomAttribute('visibility', visibilityState());
    onVisibilityChange(state => {
      this.setCustomAttribute('currentVisibility', state);
      if (state === 'hidden' && !this.wasHidden) {
        this.wasHidden = true;
        this.setCustomAttribute('visibility', 'hidden');
      }
    });
  }
  getCacheStatusData() {
    const cacheStatusData = {};
    this.performanceEntries().forEach(timing => {
      if (timing.name.endsWith('.js')) {
        const fileName = timing.name.replace(STATIC_DOMAIN_REGEX, '');
        cacheStatusData[fileName] = {
          cached: timing.duration <= CACHE_DURATION_MS,
          duration: timing.duration
        };
      }
    });
    return cacheStatusData;
  }
  getNumFailedImages() {
    return Array.from(document.getElementsByTagName('img')).reduce((total, ele) => {
      return ele.src && ele.naturalHeight === 0 && ele.naturalWidth === 0 ? total + 1 : total;
    }, 0);
  }
  finish(attrs, checks) {
    var _performanceGetEntrie;
    const {
      finishedTimestamp
    } = attrs;
    const hubHttpData = getHubHttpData(finishedTimestamp);
    const avgDurationBeforePreviousRhumbAborts = this.abandonedTimes.reduce((acc, duration) => {
      return acc + duration / this.abandonedTimes.length;
    }, 0);
    const reaganTiming = {};
    Object.keys(checks).forEach(marker => {
      if (checks[marker]) {
        reaganTiming[`marker_timing_${marker}`] = checks[marker].timestamp;
      }
    });
    this.setCustomAttribute('numReaganChecksStarted', this.abandonedTimes.length + 1);
    this.setCustomAttribute('numPreviousReaganChecksAborted', this.abandonedTimes.length);
    this.setCustomAttribute('avgDurationBeforePreviousReaganAborts', avgDurationBeforePreviousRhumbAborts);
    this.setCustomAttribute('numPreviousReaganChecksFailed', 0);
    this.setCustomAttribute('numPreviousReaganChecksSuccessful', 0);
    const activationStartMs =
    // @ts-expect-error PerformanceEntry.activationStart is only available in Chromium
    ((_performanceGetEntrie = performanceGetEntriesByType('navigation')) === null || _performanceGetEntrie === void 0 || (_performanceGetEntrie = _performanceGetEntrie[0]) === null || _performanceGetEntrie === void 0 ? void 0 : _performanceGetEntrie.activationStart) || 0;
    Raven.capturePageEvent('rhumbFinished', {
      extra: Object.assign({}, attrs, hubHttpData, {
        numChecksStarted: this.abandonedTimes.length + 1,
        numPreviousChecksAborted: this.abandonedTimes.length,
        avgDurationBeforePreviousRhumbAborts,
        numFailedImages: this.getNumFailedImages(),
        allVisibleMarkers: JSON.stringify(Object.keys(checks)),
        wasPrerendered: this.prerendering || activationStartMs > 0
      }, reaganTiming)
    });

    // send to Cartographer
    this.sendActions([{
      to: {
        pathname: attrs.pathname,
        route: attrs.route,
        scenario: attrs.scenario
      },
      status: attrs.status,
      wasHidden: this.wasHidden,
      isHidden: visibilityState() === 'hidden',
      duration: attrs.timeToAllSuccess || attrs.finishedTimestamp,
      failureType: attrs.failureType,
      isReload: isReload()
    }], CartographerEndpoint.Navigation);
  }
  report(action) {
    if (action.type === 'COMPONENT_RENDERED' || !this || this.finished) {
      return;
    }
    const {
      entry: {
        timestamp,
        checks,
        expiredTimestamp,
        pathname
      },
      routeSpec
    } = action.payload;
    switch (action.type) {
      case 'ROUTE_SUCCEEDED':
      case 'ROUTE_PARTIAL_SUCCESS':
      case 'ROUTE_FAILED':
      case 'ROUTE_TIMEOUT_EXPIRED':
      case 'ROUTE_UNEXPECTED':
        {
          this.finished = true;
          break;
        }
      default:
    }
    const isAiCopilotEnabled = String(getIsAiCopilotEnabled());
    switch (action.type) {
      case 'ROUTE_STARTED':
        {
          if (this.lastAbandonedTimestamp) {
            this.abandonedTimes.push(timestamp - this.lastAbandonedTimestamp);
          }
          break;
        }
      case 'ROUTE_ABANDONED':
        {
          this.lastAbandonedTimestamp = timestamp;
          break;
        }
      case 'ROUTE_PARTIAL_SUCCESS':
        {
          const {
            partialSuccess,
            route
          } = routeSpec;
          const {
            extra: {
              scenario
            }
          } = action.payload;
          const maxTimestamp = Math.max(...partialSuccess[scenario].map(m => checks[m].timestamp));
          const duration = Math.max(0, maxTimestamp - timestamp);
          const timeToAllSuccessMs = timestamp + duration;
          if (!this.wasHidden) {
            PageLoadMetrics.timer('partial_success', {
              scenario,
              route: cleanseRoute(route),
              isAiCopilotEnabled
            }).update(timeToAllSuccessMs);
          }
          this.finish(Object.assign({
            status: 'partial_success',
            timeToAllSuccess: timeToAllSuccessMs / 1000,
            scenario,
            finishedTimestamp: maxTimestamp,
            route,
            pathname
          }, this.options.timingOffset ? {
            adjustedTimeToAllSuccess: (timestamp + duration + this.options.timingOffset) / 1000,
            timingOffset: this.options.timingOffset
          } : {}), checks);

          // We can separate this mark if needed
          // Requires 2 changes in Hubspot/v8-runtime-perf
          this.performanceMark(`mark_all_success`);
          break;
        }
      case 'ROUTE_SUCCEEDED':
        {
          var _performanceGetEntrie2;
          const {
            success,
            route
          } = routeSpec;
          const {
            extra: {
              scenario
            }
          } = action.payload;
          if (process.env.NODE_ENV !== 'production') {
            invariant(success[scenario].length > 0, 'routeSpec for %s must have at least one `success` marker for %s', route, scenario);
          }
          const maxTimestamp = Math.max(...success[scenario].map(m => checks[m].timestamp));
          const duration = Math.max(0, maxTimestamp - timestamp);
          const timeToAllSuccessMs = timestamp + duration;
          if (!this.wasHidden) {
            PageLoadMetrics.timer('succeeded', {
              scenario,
              route: cleanseRoute(route),
              isAiCopilotEnabled
            }).update(timeToAllSuccessMs);
          }
          const activationStartMs =
          // @ts-expect-error PerformanceEntry.activationStart is only available in Chromium
          ((_performanceGetEntrie2 = performanceGetEntriesByType('navigation')) === null || _performanceGetEntrie2 === void 0 || (_performanceGetEntrie2 = _performanceGetEntrie2[0]) === null || _performanceGetEntrie2 === void 0 ? void 0 : _performanceGetEntrie2.activationStart) || 0;

          // Only send succeeded-prerender if there is an activationStart.
          // This will miss data in which the page reached rhumb-success while prerendering.
          // To collect activationStart on prerendered pages that reached ROUTE_SUCCEEDED
          // would require an eventListener to send the 'succeeded-prerender' metric
          // after both 'ROUTE_SUCCEEDED' and the 'prerenderingchange' event.
          if (activationStartMs > 0) {
            PageLoadMetrics.timer('succeeded-prerender', {
              scenario,
              route: cleanseRoute(route),
              isAiCopilotEnabled
            }).update(timeToAllSuccessMs - activationStartMs);
          }
          this.finish(Object.assign({
            status: 'success',
            timeToAllSuccess: timeToAllSuccessMs / 1000,
            scenario,
            finishedTimestamp: maxTimestamp,
            route,
            pathname
          }, this.options.timingOffset ? {
            adjustedTimeToAllSuccess: (timestamp + duration + this.options.timingOffset) / 1000,
            timingOffset: this.options.timingOffset
          } : {}), checks);
          this.performanceMark(`mark_all_success`);
          break;
        }
      case 'ROUTE_FAILED':
        {
          const {
            route,
            error
          } = routeSpec;
          const markers = error.filter(marker => checks[marker]);
          if (process.env.NODE_ENV !== 'production') {
            invariant(markers.length > 0, 'routeSpec for %s must have at least one `failure` marker for', route);
          }

          // TODO how to report multiple failed markers without separate page actions

          const [failedMarker] = markers;
          const finishedTimestamp = checks[failedMarker].timestamp;
          if (!this.wasHidden) {
            PageLoadMetrics.counter('failed', {
              route: cleanseRoute(route),
              selector: failedMarker,
              isAiCopilotEnabled
            }).increment();
          }
          this.finish({
            status: 'failure',
            failureType: 'errorSelector',
            selector: failedMarker,
            finishedTimestamp,
            route,
            pathname
          }, checks);
          this.performanceMark(`mark_all_failure`);
          break;
        }
      case 'ROUTE_TIMEOUT_EXPIRED':
        {
          const {
            route
          } = routeSpec;
          if (!this.wasHidden) {
            PageLoadMetrics.counter('timeouts', {
              route: cleanseRoute(route),
              isAiCopilotEnabled
            }).increment();
          }
          this.finish({
            status: 'failure',
            failureType: 'watchdogExpired',
            finishedTimestamp: expiredTimestamp,
            route
          }, checks);
          this.performanceMark(`mark_all_failure_watchdog_expired`);
          break;
        }
      default:
    }
  }
}