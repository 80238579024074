module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "documents-lib": "static-1.25471"
    },
    "depPathPrefixes": {
      "documents-lib": "/documents-lib/static-1.25471"
    },
    "project": "documents-lib",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};