/**
 * Analogous to HubStyleTokens times module with a few less entries that weren't ported over (or weren't being used to begin with)
 * Constants defined in this file are updated to '0ms' when running as part of tests, essentially disabling animations/transitions
 *
 * @see https://git.hubteam.com/HubSpot/UIComponents/blob/518efdbcc330a03c13a46a06a5676af1a7c501d8/HubStyleTokens/static/js/times.ts
 * @see https://git.hubteam.com/HubSpot/hs-test-utils/blob/a9ab10a26bb6d69a847defac2c8a1e737992ae15/hs-test-utils/static/js/internal/fixes/foundations-components/transition.ts#L73-L81
 */

/**
 * Warning: make sure to update hs-test-utils-tests snapshots accordingly after adding, removing or updating constants from this file
 *
 * - Merge the PR introducing the necessary changes to this file
 * - Wait for the new release of foundations-components to be published
 * - Run the following command in hs-test-utils folder:
 *
 * ```
 * bend reactor jest-watch --updateSnapshot
 * ```
 *
 * - Tail into the `jest-watch` process (press `t` and then `1`) and run all tests (press `a`)
 * - Create a PR with the updated snapshot
 *
 * @see https://git.hubteam.com/HubSpot/hs-test-utils/blob/master/hs-test-utils-tests/static/__tests__/testing-library/internal/fixes/foundations-components/transition.test.ts
 */
export const ACCORDION_TRANSITION_TIMING = '200ms';
export const DEFAULT_MODAL_TRANSITION_TIMING = '350ms';
export const EDITABLE_CONTROLS_TRANSITION_TIMING = '250ms';
export const FLOATING_ALERT_DEFAULT_TIMING = '4000ms';
export const FLOATING_ALERT_TIMING = '300ms';
export const HOVERLAY_TRANSITION_TIMING = '200ms';
export const MODAL_BACKGROUND_TRANSITION_TIMING = '100ms';
export const PANEL_NAVIGATOR_TRANSITION_TIMING = '400ms';
export const PANEL_TRANSITION_TIMING = '150ms';
export const POPOVER_ANIMATION_DURATION = '200ms';
export const SLIDEIN_MODAL_TRANSITION_TIMING = '300ms';
export const TOOLTIP_DELAY = '300ms';
export const TOOLTIP_DELAY_ON_HOLD = '100ms';
export const NAV_LIST_TRANSITION_TIMING = '200ms';
export const POPOVER_TRANSITION_TIMING = '200ms';