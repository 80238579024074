/**
 * @param {Array} arr
 * @param {*} fulcrum
 * @return A copy of the given array "rotated" so that the `fulcrum` is the first entry and the
 * entry before the `fulcrum` is the last
 */
export const rotateAround = (arr, fulcrum) => {
  const fulcrumIndex = arr.indexOf(fulcrum);
  return fulcrumIndex === -1 ? arr : arr.slice(fulcrumIndex).concat(arr.slice(0, fulcrumIndex));
};
export const includes = (list, item) => list.includes(item);