import { css } from 'styled-components';
import { rgba } from '../core/Color';
import { remCalc } from '../core/Style';
import { toShorthandSize } from './propTypes/tshirtSize';
import { INPUT_VERTICAL_ALIGNMENT, WEB_FONT_FAMILY, WEB_FONT_BOLD_WEIGHT, WEB_FONT_DEMI_BOLD_WEIGHT, WEB_FONT_MEDIUM_WEIGHT, WEB_FONT_ITALIC_WEIGHT, WEB_FONT_REGULAR_WEIGHT } from 'HubStyleTokens/misc';
import { BASE_SHADOW_ALPHA, BASE_SHADOW_BLUR_RADIUS, BASE_SHADOW_OFFSET_Y, BUTTON_RADIUS, BUTTON_DEFAULT_PADDING_Y, BUTTON_DEFAULT_PADDING_X, GRID_BREAKPOINT_SMALL, DISTANCE_MEASUREMENT_EXTRA_SMALL, DISTANCE_MEASUREMENT_SMALL, DISTANCE_MEASUREMENT_MEDIUM, DISTANCE_MEASUREMENT_LARGE, DISTANCE_MEASUREMENT_EXTRA_LARGE, LAYOUT_MAX_WIDTH, LAYOUT_PADDING_X, FOCUS_RING_SHADOW_ALPHA, FOCUS_RING_SHADOW_SIZES, FOCUS_RING_SHADOW_LAST_SIZES, INPUT_DEFAULT_HEIGHT, INPUT_BORDER_WIDTH, INPUT_FONT_SIZE } from 'HubStyleTokens/sizes';
import { FOCUS_RING_BASE } from 'HubStyleTokens/colors';
import { BASE_SHADOW_COLOR, FORM_CONTROL_FOREGROUND_COLOR, FORM_CONTROL_BACKGROUND_COLOR, FORM_CONTROL_BORDER_COLOR } from 'HubStyleTokens/theme';
import { MICRO_TRANSITION_TIMING } from 'HubStyleTokens/times';
export const setFontSize = size => css(["font-size:", ";"], remCalc(size));
export const setFontSmoothing = smoothing => {
  const computedSmoothing = smoothing || 'antialiased';

  // No `css` wrapper for this mixin, due to a bug in styled-components v3
  return `
    -webkit-font-smoothing: ${computedSmoothing};
    -moz-osx-font-smoothing: ${computedSmoothing === 'antialiased' ? 'grayscale' : computedSmoothing};
  `;
};

// note: just a template literal, not using css function
export const setFontStack = family => `
  font-family: ${family};
`;
export const FONT_FAMILIES = {
  bold: () => {
    return `
        ${setFontStack(WEB_FONT_FAMILY)};
        font-weight: ${WEB_FONT_BOLD_WEIGHT};
      `;
  },
  demibold: () => {
    return `
        ${setFontStack(WEB_FONT_FAMILY)};
        font-weight: ${WEB_FONT_DEMI_BOLD_WEIGHT};
      `;
  },
  medium: () => {
    return `
        ${setFontStack(WEB_FONT_FAMILY)};
        font-weight: ${WEB_FONT_MEDIUM_WEIGHT};
      `;
  },
  italic: () => {
    return `
        ${setFontStack(WEB_FONT_FAMILY)};
        font-style: italic;
        font-weight: ${WEB_FONT_ITALIC_WEIGHT};
      `;
  },
  default: () => {
    return `
        ${setFontStack(WEB_FONT_FAMILY)};
        font-weight: ${WEB_FONT_REGULAR_WEIGHT};
      `;
  }
};
export const setUiTransition = (property, timing) => {
  const computedTiming = timing || MICRO_TRANSITION_TIMING;
  const computedProperty = property || 'all';
  return css(["transition:", " ", " ease-out;"], computedProperty, computedTiming);
};

/**
 * A mixin that simulates focus styling. Needed when the semantic element with the focus is hidden,
 * such as the `<input>` in `UICheckbox`.
 */
export const uiFocus = css(["box-shadow:", " ", ",", " ", " !important;"], FOCUS_RING_SHADOW_SIZES, rgba(FOCUS_RING_BASE, parseFloat(FOCUS_RING_SHADOW_ALPHA)), FOCUS_RING_SHADOW_LAST_SIZES, FOCUS_RING_BASE);
export const uiLayerShadow = () => css(["box-shadow:0 ", " ", " 0 ", ";"], BASE_SHADOW_OFFSET_Y, BASE_SHADOW_BLUR_RADIUS, rgba(BASE_SHADOW_COLOR, parseFloat(BASE_SHADOW_ALPHA)));
export const toPx = value => typeof value === 'number' ? `${value}px` : value;
export const toPercent = value => value == null ? undefined : `${value}%`;
export const percentToVW = width => {
  if (typeof width !== 'string') return width;
  return width.replace(/(\d+)%/g, '$1vw');
};
export const setBorderRadius = (radius = BUTTON_RADIUS) => css(["border-radius:", ";"], toPx(radius));
export const setGrabCursor = (cursor = 'grab') => css(["cursor:move;cursor:", ";"], cursor);
export const setInputAppearance = () => css(["display:block;height:", ";width:100%;font-size:", ";line-height:22px;text-align:left;vertical-align:", ";color:", ";background-color:", ";border:", " solid ", ";border-radius:", ";"], INPUT_DEFAULT_HEIGHT, INPUT_FONT_SIZE, INPUT_VERTICAL_ALIGNMENT, FORM_CONTROL_FOREGROUND_COLOR, FORM_CONTROL_BACKGROUND_COLOR, INPUT_BORDER_WIDTH, FORM_CONTROL_BORDER_COLOR, BUTTON_RADIUS);
export const setInputMetrics = (padVertical = BUTTON_DEFAULT_PADDING_Y, padHorizontal = BUTTON_DEFAULT_PADDING_X, padRight = padHorizontal, padBottom = padVertical, responsive = true) => {
  return css(["padding-bottom:", ";padding-left:", ";padding-right:", ";padding-top:", ";", ";"], toPx(padBottom), toPx(padHorizontal), toPx(padRight), toPx(padVertical), responsive ? css(["@media only screen and (max-width:", "){width:100%;}"], GRID_BREAKPOINT_SMALL) : null);
};
const DISTANCE_SIZES = {
  flush: 0,
  xs: DISTANCE_MEASUREMENT_EXTRA_SMALL,
  sm: DISTANCE_MEASUREMENT_SMALL,
  md: DISTANCE_MEASUREMENT_MEDIUM,
  lg: DISTANCE_MEASUREMENT_LARGE,
  xl: DISTANCE_MEASUREMENT_EXTRA_LARGE
};
export const setDistance = (distance = 'md') => {
  const size = DISTANCE_SIZES[toShorthandSize(distance)];
  return css(["margin-top:", ";margin-bottom:", ";"], size, size);
};

/**
 * Given styles, wraps them in selectors to apply to placeholder text in an input or textarea.
 */
export const getPlaceholderStyles = styles => css(["&::-moz-placeholder{opacity:1;", ";}&:-ms-input-placeholder{", ";}&::-webkit-input-placeholder{", ";}"], styles, styles, styles);

/**
 * @returns a mixin that sets the appropriate width and margins to match a page template with
 * `pageLayout="max-width"`.
 */
export const setTemplateMaxWidth = () => css(["margin-left:auto;margin-right:auto;max-width:", ";"], toPx(parseInt(LAYOUT_MAX_WIDTH, 10) + parseInt(LAYOUT_PADDING_X, 10) * 2));