export default {
  "ko": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 0,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ",",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n%u",
          "units": {
            "byte": "바이트",
            "gb": "기가바이트",
            "kb": "킬로바이트",
            "mb": "메가바이트",
            "tb": "테라바이트"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " 및 ",
            "lastWordConnector": " 및 "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " 또는 ",
            "lastWordConnector": " 또는 "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "other": "0천"
            },
            "10000": {
              "other": "0만"
            },
            "100000": {
              "other": "00만"
            },
            "1000000": {
              "other": "000만"
            },
            "10000000": {
              "other": "0000만"
            },
            "100000000": {
              "other": "0억"
            },
            "1000000000": {
              "other": "00억"
            },
            "10000000000": {
              "other": "000억"
            },
            "100000000000": {
              "other": "0000억"
            },
            "1000000000000": {
              "other": "0조"
            },
            "10000000000000": {
              "other": "00조"
            },
            "100000000000000": {
              "other": "000조"
            }
          },
          "short": {
            "1000": {
              "other": "0천"
            },
            "10000": {
              "other": "0만"
            },
            "100000": {
              "other": "00만"
            },
            "1000000": {
              "other": "000만"
            },
            "10000000": {
              "other": "0000만"
            },
            "100000000": {
              "other": "0억"
            },
            "1000000000": {
              "other": "00억"
            },
            "10000000000": {
              "other": "000억"
            },
            "100000000000": {
              "other": "0000억"
            },
            "1000000000000": {
              "other": "0조"
            },
            "10000000000000": {
              "other": "00조"
            },
            "100000000000000": {
              "other": "000조"
            }
          }
        }
      }
    }
  }
}