import { deepMerge } from '../transitional/utils/deepMerge';

/**
 * Deep merge multiple style overrides.
 */
export const mergeOverrides = (...overrides) => {
  return overrides.reduce((acc, override) => {
    if (override !== undefined) {
      return deepMerge(acc, override);
    }
    return acc;
  }, {});
};