// https://github.com/microsoft/TypeScript/issues/1863

/**
 * @param {any} key
 * @return {boolean} `true` if this is a valid `WeakMap` key, `false` otherwise
 */
const isObject = key => typeof key === 'object' && key != null || typeof key === 'function';

/**
 * @param {Object} object
 * @return {boolean} `true` if this is an Immutable-style object with `hashCode()` and `equals()`
 */
const isHashable = object => typeof object.hashCode === 'function' && typeof object.equals === 'function';

/**
 * @param {string|number|boolean|symbol} primitive
 * @return {string|symbol} An object key that's unique to this primitive
 */
const primitiveKey = primitive => typeof primitive === 'symbol' ? primitive : `${typeof primitive}-${primitive}`;

/**
 * A `CacheLayer` may contain any mix of:
 * 1. A value (`getValue()`)
 * 2. Nested layers (`get(key)`)
 */
export class CacheLayer {
  constructor() {
    this.objectCache = null;
    this.hashableCache = null;
    this.primitiveCache = null;
    this.value = null;
  }
  get(key) {
    if (isObject(key)) {
      if (isHashable(key) && this.hashableCache) {
        const hashableCacheResult = this.hashableCache[key.hashCode()];
        if (hashableCacheResult && key.equals(hashableCacheResult[0])) {
          return hashableCacheResult[1];
        }
      }
      return this.objectCache && this.objectCache.get(key);
    }
    return this.primitiveCache && this.primitiveCache[primitiveKey(key)];
  }
  set(key, cacheLayer) {
    if (isObject(key)) {
      if (isHashable(key)) {
        if (!this.hashableCache) this.hashableCache = Object.create(null);
        this.hashableCache[key.hashCode()] = [key, cacheLayer];
      }
      if (!this.objectCache) this.objectCache = new WeakMap();
      this.objectCache.set(key, cacheLayer);
      return;
    }
    if (!this.primitiveCache) this.primitiveCache = Object.create(null);
    this.primitiveCache[primitiveKey(key)] = cacheLayer;
  }
  getValue() {
    return this.value;
  }
  setValue(value) {
    this.value = value;
  }
}

/**
 * Retrieves a value from a layered cache, if that value exists.
 * @param {CacheLayer} cacheLayer
 * @param {...any} nestedKeys
 * @return {any}
 */
export const cacheGet = (cacheLayer, nestedKeys) => {
  let layer = cacheLayer;
  for (let i = 0; i < nestedKeys.length; i++) {
    if (!layer) return null;
    const nextLayer = layer.get(nestedKeys[i]);
    layer = nextLayer;
  }
  return layer && layer.getValue();
};

/**
 * Inserts a value into a layered cache.
 */
export const cachePut = (cacheLayer, nestedKeys, value) => {
  let layer = cacheLayer;
  nestedKeys.forEach(key => {
    let nextLayer = layer.get(key);
    if (!nextLayer) {
      nextLayer = new CacheLayer();
      layer.set(key, nextLayer);
    }
    layer = nextLayer;
  });
  layer.setValue(value);
};