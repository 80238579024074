import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
export const useChecked = props => {
  const {
    checked,
    defaultChecked,
    onChange
  } = props;
  const [checkedState, setCheckedState] = useState(defaultChecked);
  const handleChange = useCallback(evt => {
    setCheckedState(evt.target.checked);
    if (onChange) onChange(evt);
  }, [onChange]);
  return {
    checked: checked !== undefined ? checked : checkedState,
    onChange: handleChange
  };
};
export const checkedPropTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func
};
export const checkedDefaultProps = {
  defaultChecked: false
};