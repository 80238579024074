export const objectSymmetricDifference = (obj1, obj2) => {
  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
  const result = {};
  for (const key of allKeys) {
    const inObj1 = (key in obj1);
    const inObj2 = (key in obj2);
    if (inObj1 !== inObj2) {
      // XOR - true if key is in exactly one object
      result[key] = inObj1 ? obj1[key] : obj2[key];
    }
  }
  return result;
};