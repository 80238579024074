import PropTypes from 'prop-types';
import { Children, Component } from 'react';
import getPDFPageState from '../../selectors/getPDFPageState';
const KEY_CODES = {
  UP: 38,
  LEFT: 37,
  DOWN: 40,
  RIGHT: 39
};
export default class PDFKeyControls extends Component {
  constructor(...args) {
    super(...args);
    this.handleKeydown = e => {
      const {
        onPage,
        pageIndex
      } = this.props;
      const {
        previousPageDisabled,
        nextPageDisabled
      } = getPDFPageState(this.props);
      switch (e.which) {
        case KEY_CODES.UP:
        case KEY_CODES.LEFT:
          if (previousPageDisabled) {
            return;
          }
          onPage(pageIndex - 1);
          return;
        case KEY_CODES.DOWN:
        case KEY_CODES.RIGHT:
          if (nextPageDisabled) {
            return;
          }
          onPage(pageIndex + 1);
          return;
        default:
          return;
      }
    };
  }
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeydown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  }
  render() {
    return Children.only(this.props.children);
  }
}
PDFKeyControls.propTypes = {
  children: PropTypes.any,
  onPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired
};