import { createGlobalStyle } from 'styled-components';
import { classicLightCssVars } from 'foundations-theming/themes/classic/light/cssVars';
import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
import { getCssVars } from 'foundations-theming/components/ThemeProvider/ThemeProvider.helpers';
export const APPLIED_CSS_VARS_FLAG = '--css-vars-applied';
export const GlobalCSSVariables = createGlobalStyle(({
  themeName,
  themeMode
}) => {
  const [cssVars, setCSSVars] = useState(classicLightCssVars);
  useEffect(() => {
    getCssVars(themeName, themeMode).then(cssVarsObject => {
      setCSSVars(cssVarsObject);
    }).catch(error => {
      devLogger.warn({
        message: `Failed to load CSS Variables with error: ${error}`,
        key: 'foundations-components:global-styles:css-vars-load-failure'
      });
    });
  }, [themeName, themeMode]);
  return {
    ':root': Object.assign({}, cssVars, {
      [APPLIED_CSS_VARS_FLAG]: 'true'
    })
  };
});