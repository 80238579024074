'use es6';

import getDateNumberFormattingLocale from './getDateNumberFormattingLocale';
import getMomentToDateValue from './internal/getMomentToDateValue';
export default ((value, type) => {
  const dateValue = getMomentToDateValue(value, type);
  const dateFormatter = new Intl.DateTimeFormat(getDateNumberFormattingLocale(), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  });
  return dateFormatter.format(dateValue);
});