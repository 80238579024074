export const COPILOT_INITIALIZE_OPEN = 'copilot-initialize-open';
export const getShouldStartWithCopilotOpen = () => {
  try {
    const isCopilotInitiallyOpen = sessionStorage.getItem(COPILOT_INITIALIZE_OPEN);
    // '0' = closed | '1' = open | '2' = open expanded view
    return isCopilotInitiallyOpen === '1' || isCopilotInitiallyOpen === '2' ||
    // TODO - @mlegocki: remove this line once we've merged in other related PRs to avoid regressions
    // PR: https://git.hubteam.com/HubSpot/chatspot-ui/pull/2483
    isCopilotInitiallyOpen === 'true';
  } catch (e) {
    return false;
  }
};