// THIS FILE IS AUTOMATICALLY GENERATED. DO NOT MODIFY IT MANUALLY.

/**
 * Design System theme object.
 *
 * Theme name: `classic`
 *
 * Theme mode: `light`
 */
export default {
  color: {
    'fill-surface-default': '#ffffff',
    'fill-surface-default-hover': '#242424',
    'fill-surface-default-pressed': '#292929',
    'fill-surface-recessed': '#253342',
    'fill-surface-raised': '#F5F8FA',
    'fill-surface-overlay': '#333333',
    'fill-primary-default': '#FF7A59',
    'fill-primary-hover': '#FF8F73',
    'fill-primary-pressed': '#E66E50',
    'fill-primary-disabled': '#EAF0F6',
    'fill-primary-subtle': '#FFF1EE',
    'fill-secondary-default': '#00A4BD',
    'fill-secondary-hover': '#7FD1DE',
    'fill-secondary-pressed': '#0091AE',
    'fill-secondary-disabled': '#EAF0F6',
    'fill-secondary-subtle': '#E5F5F8',
    'fill-tertiary-default': '#EAF0F6',
    'fill-tertiary-hover': '#F5F8FA',
    'fill-tertiary-pressed': '#CBD6E2',
    'fill-tertiary-disabled': '#EAF0F6',
    'fill-tertiary-subtle': '#DFE3EB',
    'fill-brand-default': '#FF7A59',
    'fill-brand-hover': '#FF8F73',
    'fill-brand-pressed': '#E66E50',
    'fill-brand-subtle': '#FFF1EE',
    'fill-positive-default': '#00BDA5',
    'fill-positive-hover': '#7FDED2',
    'fill-positive-pressed': '#00A38D',
    'fill-positive-subtle': '#E5F8F6',
    'fill-positive-alt': '#38D84C',
    'fill-caution-default': '#F5C26B',
    'fill-caution-hover': '#FAE0B5',
    'fill-caution-pressed': '#DBAE60',
    'fill-caution-subtle': '#FEF8F0',
    'fill-caution-alt': '#FCCB57',
    'fill-alert-default': '#F2545B',
    'fill-alert-hover': '#FF5962',
    'fill-alert-pressed': '#D94C53',
    'fill-alert-subtle': '#FDEDEE',
    'fill-info-default': '#00A4BD',
    'fill-info-hover': '#7FD1DE',
    'fill-info-pressed': '#0091AE',
    'fill-info-subtle': '#E5F5F8',
    'fill-accent-purple-default': '#6A78D1',
    'fill-accent-purple-hover': '#B4BBE8',
    'fill-accent-purple-pressed': '#5E6AB8',
    'fill-accent-purple-subtle': '#F0F1FA',
    'fill-accent-orange-default': '#FF7A59',
    'fill-accent-orange-hover': '#FFBCAC',
    'fill-accent-orange-pressed': '#E66E50',
    'fill-accent-orange-subtle': '#FFF1EE',
    'fill-accent-green-default': '#00BDA5',
    'fill-accent-green-hover': '#7FDED2',
    'fill-accent-green-pressed': '#00A38D',
    'fill-accent-green-subtle': '#E5F8F6',
    'fill-accent-yellow-default': '#F5C26B',
    'fill-accent-yellow-hover': '#FAE0B5',
    'fill-accent-yellow-pressed': '#DBAE60',
    'fill-accent-yellow-subtle': '#FEF8F0',
    'fill-accent-red-default': '#F2545B',
    'fill-accent-red-hover': '#F8A9AD',
    'fill-accent-red-pressed': '#D94C53',
    'fill-accent-red-subtle': '#FDEDEE',
    'fill-accent-blue-default': '#00A4BD',
    'fill-accent-blue-hover': '#7FD1DE',
    'fill-accent-blue-pressed': '#0091AE',
    'fill-accent-blue-subtle': '#E5F5F8',
    'fill-accent-teal-default': '#007C7D',
    'fill-accent-teal-hover': '#006162',
    'fill-accent-teal-pressed': '#00494B',
    'fill-accent-teal-subtle': '#051919',
    'fill-accent-magenta-default': '#F2547D',
    'fill-accent-magenta-hover': '#F9AABE',
    'fill-accent-magenta-pressed': '#D94C71',
    'fill-accent-magenta-subtle': '#FDEDF2',
    'fill-transparent-default': 'rgba(0,0,0,0)',
    'fill-field-default': '#F5F8FA',
    'fill-field-hover': '#F5F8FA',
    'fill-field-pressed': '#F5F8FA',
    'fill-field-disabled': '#EAF0F6',
    'text-primary-default': '#33475B',
    'text-primary-subtle': '#516F90',
    'text-primary-disabled': '#99ACC2',
    'text-primary-on-fill-default': '#ffffff',
    'text-primary-on-fill-subtle': '#B6B1AF',
    'text-interactive-default': '#0091AE',
    'text-interactive-default-hover': '#007A8C',
    'text-interactive-default-pressed': '#7FD1DE',
    'text-interactive-on-fill-default': '#FFFFFF',
    'text-interactive-on-fill-subtle': '#C4B4F7',
    'text-brand-default': '#FF7A59',
    'text-brand-on-fill-default': '#ffffff',
    'text-brand-on-fill-subtle': '#FFA581',
    'text-positive-default': '#00BDA5',
    'text-positive-on-fill-default': '#ffffff',
    'text-positive-on-fill-subtle': '#38D84C',
    'text-positive-on-fill-alt': '#042905',
    'text-caution-default': '#D39913',
    'text-caution-on-fill-default': '#1F1F1F',
    'text-caution-on-fill-subtle': '#EEB117',
    'text-caution-on-fill-alt': '#331E06',
    'text-alert-default': '#F2545B',
    'text-alert-on-fill-default': '#FFFFFF',
    'text-alert-on-fill-subtle': '#FF7B70',
    'text-info-default': '#5CAAFF',
    'text-info-on-fill-default': '#FFFFFF',
    'text-info-on-fill-subtle': '#8CC4F4',
    'text-accent-green-default': '#27BD3E',
    'text-accent-green-on-fill-default': '#FFFFFF',
    'text-accent-green-on-fill-subtle': '#38D84C',
    'text-accent-yellow-default': '#D39913',
    'text-accent-yellow-on-fill-default': '#FFFFFF',
    'text-accent-yellow-on-fill-subtle': '#EEB117',
    'text-accent-red-default': '#FF7B70',
    'text-accent-red-on-fill-default': '#FFFFFF',
    'text-accent-red-on-fill-subtle': '#FFA499',
    'text-accent-blue-default': '#5CAAFF',
    'text-accent-blue-on-fill-default': '#FFFFFF',
    'text-accent-blue-on-fill-subtle': '#8CC4F4',
    'text-accent-teal-default': '#21B7B7',
    'text-accent-teal-on-fill-default': '#FFFFFF',
    'text-accent-teal-on-fill-subtle': '#48D1CF',
    'text-accent-magenta-default': '#FF72BA',
    'text-accent-magenta-on-fill-default': '#FFFFFF',
    'text-accent-magenta-on-fill-subtle': '#FF9FCC',
    'text-accent-purple-default': '#AC98F8',
    'text-accent-purple-on-fill-default': '#FFFFFF',
    'text-accent-purple-on-fill-subtle': '#C4B4F7',
    'text-core-default': '#33475B',
    'text-core-subtle': '#516F90',
    'text-core-disabled': '#99ACC2',
    'text-secondary-default': '#FF7A59',
    'text-secondary-disabled': '#99ACC2',
    'text-tertiary-default': '#516F90',
    'text-tertiary-disabled': '#99ACC2',
    'icon-primary-default': '#7C98B6',
    'icon-primary-hover': '#7C98B6',
    'icon-primary-pressed': '#33475B',
    'icon-primary-disabled': '#99ACC2',
    'icon-primary-on-fill-default': '#ffffff',
    'icon-primary-on-fill-subtle': '#B6B1AF',
    'icon-interactive-default': '#0091AE',
    'icon-interactive-hover': '#007A8C',
    'icon-interactive-pressed': '#7FD1DE',
    'icon-interactive-on-fill-default': '#ffffff',
    'icon-interactive-on-fill-subtle': '#C4B4F7',
    'icon-brand-default': '#FFA581',
    'icon-brand-on-fill-default': '#411204',
    'icon-brand-on-fill-subtle': '#FFA581',
    'icon-positive-default': '#38D84C',
    'icon-positive-on-fill-default': '#FFFFFF',
    'icon-positive-on-fill-subtle': '#38D84C',
    'icon-positive-on-fill-alt': '#042905',
    'icon-caution-default': '#EEB117',
    'icon-caution-on-fill-default': '#1F1F1F',
    'icon-caution-on-fill-subtle': '#EEB117',
    'icon-caution-on-fill-alt': '#331E06',
    'icon-alert-default': '#F2545B',
    'icon-alert-hover': '#F8A9AD',
    'icon-alert-pressed': '#D94C53',
    'icon-alert-on-fill-default': '#ffffff',
    'icon-alert-on-fill-subtle': '#FF7B70',
    'icon-info-default': '#8CC4F4',
    'icon-info-on-fill-default': '#FFFFFF',
    'icon-info-on-fill-subtle': '#8CC4F4',
    'icon-accent-green-default': '#38D84C',
    'icon-accent-green-on-fill-default': '#FFFFFF',
    'icon-accent-green-on-fill-subtle': '#38D84C',
    'icon-accent-yellow-default': '#EEB117',
    'icon-accent-yellow-on-fill-default': '#1F1F1F',
    'icon-accent-yellow-on-fill-subtle': '#EEB117',
    'icon-accent-red-default': '#FF3842',
    'icon-accent-red-on-fill-default': '#FFFFFF',
    'icon-accent-red-on-fill-subtle': '#FF7B70',
    'icon-accent-blue-default': '#8CC4F4',
    'icon-accent-blue-on-fill-default': '#FFFFFF',
    'icon-accent-blue-on-fill-subtle': '#8CC4F4',
    'icon-accent-teal-default': '#48D1CF',
    'icon-accent-teal-on-fill-default': '#FFFFFF',
    'icon-accent-teal-on-fill-subtle': '#48D1CF',
    'icon-accent-magenta-default': '#FF9FCC',
    'icon-accent-magenta-on-fill-default': '#FFFFFF',
    'icon-accent-magenta-on-fill-subtle': '#FF9FCC',
    'icon-accent-purple-default': '#C4B4F7',
    'icon-accent-purple-on-fill-default': '#FFFFFF',
    'icon-accent-purple-on-fill-subtle': '#C4B4F7',
    'icon-accent-orange-default': '#FFA581',
    'icon-accent-orange-on-fill-default': '#411204',
    'icon-accent-orange-on-fill-subtle': '#FFA581',
    'icon-core-default': '#33475B',
    'icon-core-subtle': '#7C98B6',
    'icon-secondary-default': '#FF7A59',
    'icon-secondary-disabled': '#99ACC2',
    'icon-tertiary-default': '#516F90',
    'icon-tertiary-disabled': '#99ACC2',
    'border-primary-default': '#CBD6E2',
    'border-primary-subtle': '#DFE3EB',
    'border-primary-on-fill': '#ffffff',
    'border-primary-disabled': '#EAF0F6',
    'border-primary-subtle-default': '#FFFFFF',
    'border-primary-subtle-hover': '#FFFFFF',
    'border-primary-subtle-pressed': '#7C98B6',
    'border-primary-on-fill-default': '#FFFFFF',
    'border-interactive-default': '#7FD1DE',
    'border-interactive-pressed': '#0091AE',
    'border-brand-default': '#FF7A59',
    'border-positive-default': '#00BDA5',
    'border-caution-default': '#F5C26B',
    'border-alert-default': '#F2545B',
    'border-info-default': '#00A4BD',
    'border-surface-default': '#FFFFFF',
    'border-core-default': '#CBD6E2',
    'border-core-subtle': '#DFE3EB',
    'border-core-hover': '#7C98B6',
    'border-core-pressed': '#EAF0F6',
    'border-secondary-default': '#FF7A59',
    'border-secondary-disabled': 'rgba(0,0,0,0)',
    'border-tertiary-default': '#CBD6E2',
    'border-tertiary-disabled': 'rgba(0,0,0,0)',
    'specialty-notification': '#D9002B',
    'specialty-scrim': '#000000',
    'text-interactive-default-disabled': '#99ACC2',
    'icon-secondary-subtle': '#FFFFFF',
    'fill-tertiary-alt': '#CBD6E2',
    'fill-accent-light-orange-default': '#FF8F59',
    'fill-accent-light-orange-hover': '#FFC7AC',
    'fill-accent-light-orange-pressed': '#E68250',
    'fill-accent-light-orange-subtle': '#FFF3EE',
    'fill-accent-neutral-default': '#425B76',
    'fill-accent-neutral-hover': '#516F90',
    'fill-accent-neutral-pressed': '#33475B',
    'fill-accent-neutral-subtle': '#425B76',
    'text-interactive-on-fill': '#ffffff',
    'text-alert-hover': '#F8A9AD',
    'text-alert-pressed': '#D94C53',
    'text-alert-on-fill': '#ffffff',
    'do-not-use-calypso': '#00a4bd',
    'do-not-use-calypso-dark': '#0091ae',
    'do-not-use-calypso-medium': '#7fd1de',
    'do-not-use-calypso-light': '#e5f5f8',
    'do-not-use-sorbet': '#ff8f59',
    'do-not-use-sorbet-dark': '#e68250',
    'do-not-use-sorbet-medium': '#ffc7ac',
    'do-not-use-sorbet-light': '#fff3ee',
    'do-not-use-lorax': '#ff7a59',
    'do-not-use-lorax-dark': '#e66e50',
    'do-not-use-lorax-medium': '#ffbcac',
    'do-not-use-lorax-light': '#fff1ee',
    'do-not-use-marigold': '#f5c26b',
    'do-not-use-marigold-dark': '#dbae60',
    'do-not-use-marigold-medium': '#fae0b5',
    'do-not-use-marigold-light': '#fef8f0',
    'do-not-use-candy-apple': '#f2545b',
    'do-not-use-candy-apple-dark': '#d94c53',
    'do-not-use-candy-apple-medium': '#f8a9ad',
    'do-not-use-candy-apple-light': '#fdedee',
    'do-not-use-norman': '#f2547d',
    'do-not-use-norman-dark': '#d94c71',
    'do-not-use-norman-medium': '#f9aabe',
    'do-not-use-norman-light': '#fdedf2',
    'do-not-use-thunderdome': '#6a78d1',
    'do-not-use-thunderdome-dark': '#5e6ab8',
    'do-not-use-thunderdome-medium': '#b4bbe8',
    'do-not-use-thunderdome-light': '#f0f1fa',
    'do-not-use-oz': '#00bda5',
    'do-not-use-oz-dark': '#00a38d',
    'do-not-use-oz-medium': '#7fded2',
    'do-not-use-oz-light': '#e5f8f6',
    'do-not-use-flat-earth': '#253342',
    'do-not-use-pantera': '#2d3e50',
    'do-not-use-obsidian': '#33475b',
    'do-not-use-heffalump': '#425b76',
    'do-not-use-slinky': '#516f90',
    'do-not-use-eerie': '#7c98b6',
    'do-not-use-flint': '#99acc2',
    'do-not-use-battleship': '#cbd6e2',
    'do-not-use-great-white': '#dfe3eb',
    'do-not-use-koala': '#eaf0f6',
    'do-not-use-cirrus': '#f2f5f8',
    'do-not-use-gypsum': '#f5f8fa',
    'do-not-use-olaf': '#ffffff',
    'do-not-use-monolith': '#000000',
    'do-not-use-chart-red': '#ea90b1',
    'do-not-use-chart-orange': '#fea58e',
    'do-not-use-chart-yellow': '#f5c78e',
    'do-not-use-chart-green': '#a2d28f',
    'do-not-use-chart-aqua': '#51d3d9',
    'do-not-use-chart-blue': '#81c1fd',
    'do-not-use-chart-purple': '#bda9ea',
    'do-not-use-chart-dark-purple': '#9784c2',
    'do-not-use-link-hover': '#007a8c',
    'do-not-use-button-primary-hover-fill': '#ff8f73',
    'do-not-use-button-secondary-text': '#ff7a53',
    'do-not-use-button-secondary-hover-fill': '#fff8f6',
    'do-not-use-button-secondary-active-fill': '#ffebe6',
    'do-not-use-button-tertiary-light-text': '#506e91',
    'do-not-use-button-tertiary-light-hover-fill': '#e5eaf0',
    'do-not-use-button-danger-hover-fill': '#ff5962',
    'do-not-use-button-disabled-text': '#b0c1d4',
    'do-not-use-focus-ring-base': '#00d0e4',
    'do-not-use-alert-danger': '#f04b51',
    'do-not-use-alert-warning': '#fdf3e1',
    'do-not-use-alert-success': '#5967bc',
    'do-not-use-linkedin': '#0077b5',
    'do-not-use-twitter': '#55acee',
    'do-not-use-x': '#000000',
    'do-not-use-facebook': '#3b5998',
    'do-not-use-google': '#dd4b39',
    'do-not-use-xing': '#026466',
    'do-not-use-pinterest': '#bd081c',
    'do-not-use-rss': '#f26522',
    'do-not-use-youtube': '#ff0000',
    'do-not-use-instagram': '#c13584',
    'do-not-use-transparent-dark': 'rgba(0, 0, 0, 0)',
    'do-not-use-transparent-light': 'rgba(255, 255, 255, 0)',
    'do-not-use-transparent': 'rgba(0, 0, 0, 0)'
  },
  space: {
    '0': '0px',
    '25': '1px',
    '50': '2px',
    '100': '4px',
    '200': '8px',
    '250': '10px',
    '300': '12px',
    '400': '16px',
    '500': '20px',
    '600': '24px',
    '700': '28px',
    '800': '32px',
    '900': '36px',
    '1000': '40px',
    '1100': '48px',
    '1200': '56px',
    '1300': '60px',
    '1400': '64px',
    '1500': '96px',
    '1600': '144px',
    '1700': '192px'
  },
  borderRadius: {
    '0': '0px',
    '100': '3px',
    '200': '4px',
    '300': '100px'
  },
  borderWidth: {
    '0': '0px',
    '100': '1px',
    '200': '2px',
    '300': '4px'
  },
  boxShadow: {
    '100': '0 1px 5px 0 rgba(44,61,79,0.12)',
    '200': '0 1px 4px 0 rgba(66,91,117,0.06)',
    '300': '0 3px 8px 0 rgba(0,0,0,0.1)',
    '400': '0 1px 24px 0 rgba(0,0,0,0.08)'
  },
  typography: {
    'display-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '56px',
      lineHeight: '72px',
      letterSpacing: '-0.32px'
    },
    'display-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      lineHeight: '76px',
      fontSize: '66px',
      letterSpacing: '-0.32px'
    },
    'display-300': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '76px',
      lineHeight: '88px',
      letterSpacing: '-0.32px'
    },
    'display-400': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '84px',
      lineHeight: '96px',
      letterSpacing: '-0.32px'
    },
    'display-500': {
      fontFamily: 'Lexend Deca',
      fontWeight: '500',
      fontSize: '94px',
      lineHeight: '100px',
      letterSpacing: '-0.32px'
    },
    'heading-25': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0px'
    },
    'heading-50': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0px'
    },
    'heading-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'heading-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'heading-300': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'heading-400': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '27px',
      letterSpacing: '0px'
    },
    'heading-500': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '300',
      fontSize: '24px',
      lineHeight: '29px',
      letterSpacing: '0px'
    },
    'heading-600': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '700',
      fontSize: '32px',
      lineHeight: '39px',
      letterSpacing: '0px'
    },
    'heading-700': {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: '500',
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      letterSpacing: '-0.16px'
    },
    'heading-800': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '42px',
      lineHeight: '52px',
      letterSpacing: '-0.16px'
    },
    'heading-900': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '46px',
      lineHeight: '56px',
      letterSpacing: '-0.16px'
    },
    'heading-1000': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '700',
      fontSize: '55px',
      lineHeight: '67px',
      letterSpacing: '0px'
    },
    'body-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '300',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'body-125': {
      fontFamily: 'Lexend Deca',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'body-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'body-300': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'body-400': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0px'
    },
    'body-500': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: '0px'
    },
    'body-600': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '26px',
      lineHeight: '32px',
      letterSpacing: '0px'
    },
    'body-700': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '28px',
      lineHeight: '36px',
      letterSpacing: '0px'
    },
    'detail-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '300',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0px'
    },
    'detail-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '300',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0px'
    },
    'code-100': {
      fontFamily: 'Source Code Pro, Consolas, Monaco, Courier New, monospace',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0px'
    }
  }
};