import PropTypes from 'prop-types';
import createChainablePropType from './createChainablePropType';
export const getComponentPropType = ({
  propTypes,
  defaultProps
}) => {
  return createChainablePropType(PropTypes.elementType, 'componentProp', {
    propTypes,
    defaultProps
  });
};

// Using an `any` type here because of how low-level this function is &
// its varied use across product apps
export const isRenderable = children => children != null && children !== false;