export default {
  "it": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " e ",
            "lastWordConnector": " e "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " o ",
            "lastWordConnector": " o "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 migliaio",
              "other": "0 migliaia"
            },
            "10000": {
              "one": "00 migliaia",
              "other": "00 migliaia"
            },
            "100000": {
              "one": "000 migliaia",
              "other": "000 migliaia"
            },
            "1000000": {
              "one": "0 milione",
              "other": "0 milioni"
            },
            "10000000": {
              "one": "00 milioni",
              "other": "00 milioni"
            },
            "100000000": {
              "one": "000 milioni",
              "other": "000 milioni"
            },
            "1000000000": {
              "one": "0 miliardo",
              "other": "0 miliardi"
            },
            "10000000000": {
              "one": "00 miliardi",
              "other": "00 miliardi"
            },
            "100000000000": {
              "one": "000 miliardi",
              "other": "000 miliardi"
            },
            "1000000000000": {
              "one": "0 migliaio di miliardi",
              "other": "0 migliaia di miliardi"
            },
            "10000000000000": {
              "one": "00 migliaia di miliardi",
              "other": "00 migliaia di miliardi"
            },
            "100000000000000": {
              "one": "000 migliaia di miliardi",
              "other": "000 migliaia di miliardi"
            }
          },
          "short": {
            "1000": {
              "one": "0",
              "other": "0"
            },
            "10000": {
              "one": "0",
              "other": "0"
            },
            "100000": {
              "one": "0",
              "other": "0"
            },
            "1000000": {
              "one": "0 Mln",
              "other": "0 Mln"
            },
            "10000000": {
              "one": "00 Mln",
              "other": "00 Mln"
            },
            "100000000": {
              "one": "000 Mln",
              "other": "000 Mln"
            },
            "1000000000": {
              "one": "0 Mld",
              "other": "0 Mld"
            },
            "10000000000": {
              "one": "00 Mld",
              "other": "00 Mld"
            },
            "100000000000": {
              "one": "000 Mld",
              "other": "000 Mld"
            },
            "1000000000000": {
              "one": "0 Bln",
              "other": "0 Bln"
            },
            "10000000000000": {
              "one": "00 Bln",
              "other": "00 Bln"
            },
            "100000000000000": {
              "one": "000 Bln",
              "other": "000 Bln"
            }
          }
        }
      }
    }
  }
}