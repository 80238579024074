import { useTheme as useStyledComponentsTheme } from 'styled-components';
import { getTheme } from './getTheme';

/**
 * A hook that provides type-safe access to the current theme.
 * This is the recommended way to access theme tokens in functional components.
 *
 * @returns A theme object that provides validated access to tokens.
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   const theme = useTheme(); // strongly typed theme object
 *
 *   return (
 *     <div style={{
 *       color: theme.color['text-core-default']
 *     }} />
 *   );
 * };
 * ```
 */
export const useTheme = () => {
  const theme = useStyledComponentsTheme();
  return getTheme({
    theme
  });
};