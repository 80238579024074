import BaseReporter from './BaseReporter';
import { getHubHttpData } from '../httpRequestsStats';
import { PageLoadMetrics } from '../Metrics';
import { onVisibilityChange, isHidden } from '../visibility';
const METRIC_NAME_MAP = new Map([['numSucceededRequests', 'succeeded-requests'], ['numAbortedRequests', 'aborted-requests'], ['numPendingRequests', 'pending-requests'], ['numNotFound', 'not-found-requests'], ['numTimedoutRequests', 'timed-out-requests'], ['numFailedRequestsMinus404AndRetries', 'failed-requests'], ['numRetriedFailures', 'retried-requests']]);
export default class PageLoadHTTPReporter extends BaseReporter {
  constructor(options) {
    super(options);
    this.finished = false;
    this.wasHidden = isHidden();
    onVisibilityChange(state => {
      if (state === 'hidden') {
        this.wasHidden = true;
      }
    });
  }
  sendMetrics(timestamp, dimensions = {}) {
    const stats = getHubHttpData(timestamp);
    if (!stats) {
      return;
    }
    METRIC_NAME_MAP.forEach((metricName, statName) => {
      const value = Number(stats[statName]);
      PageLoadMetrics.histogram(metricName, dimensions).update(value);
    });
  }
  report(action) {
    if (action.type === 'COMPONENT_RENDERED' || this.finished ||
    // stop reporting if page was hidden (similar to other reporters) since http requests made when page was hidden may be skipped or deprioritized
    this.wasHidden) {
      return;
    }
    const {
      entry: {
        checks,
        expiredTimestamp
      },
      routeSpec
    } = action.payload;
    switch (action.type) {
      case 'ROUTE_SUCCEEDED':
      case 'ROUTE_PARTIAL_SUCCESS':
      case 'ROUTE_FAILED':
      case 'ROUTE_TIMEOUT_EXPIRED':
      case 'ROUTE_UNEXPECTED':
        {
          this.finished = true;
          break;
        }
      default:
    }
    switch (action.type) {
      case 'ROUTE_PARTIAL_SUCCESS':
        {
          const {
            partialSuccess
          } = routeSpec;
          const {
            extra: {
              scenario
            }
          } = action.payload;
          const maxTimestamp = Math.max(...partialSuccess[scenario].map(marker => checks[marker].timestamp));
          this.sendMetrics(maxTimestamp, {
            scenario,
            status: 'partial_success'
          });
          break;
        }
      case 'ROUTE_SUCCEEDED':
        {
          const {
            success
          } = routeSpec;
          const {
            extra: {
              scenario
            }
          } = action.payload;
          const maxTimestamp = Math.max(...success[scenario].map(marker => checks[marker].timestamp));
          this.sendMetrics(maxTimestamp, {
            scenario,
            status: 'success'
          });
          break;
        }
      case 'ROUTE_FAILED':
        {
          const {
            error
          } = routeSpec;
          const markers = error.filter(marker => checks[marker]);
          const [failedMarker] = markers;
          const finishedTimestamp = checks[failedMarker].timestamp;
          this.sendMetrics(finishedTimestamp, {
            selector: failedMarker,
            status: 'failure'
          });
          break;
        }
      case 'ROUTE_TIMEOUT_EXPIRED':
        {
          if (expiredTimestamp) {
            this.sendMetrics(expiredTimestamp, {
              status: 'failure'
            });
          }
          break;
        }
      default:
    }
  }
}