import { isNaN } from '../utils/underscore';
const RGB_REGEX = /^rgb/i;
/**
 * Returns the RGB channel values for a given color
 * @param {string} color - A color in hex, rgb(), or rgba() format
 * @returns {object} An object of the form { r, g, b }
 */
export function getRGB(color) {
  let r;
  let g;
  let b;
  try {
    if (RGB_REGEX.test(color)) {
      const match = color.match(/(\d+)\D+(\d+)\D+(\d+)/);
      r = parseInt(match[1], 10);
      g = parseInt(match[2], 10);
      b = parseInt(match[3], 10);
    } else {
      let hexColor = color.toLowerCase().replace(/^#/, '');
      if (hexColor.length === 3) hexColor = hexColor.replace(/([0-9a-f])/g, '$1$1');
      r = parseInt(hexColor.substring(0, 2), 16);
      g = parseInt(hexColor.substring(2, 4), 16);
      b = parseInt(hexColor.substring(4, 6), 16);
    }
    if ([r, g, b].some(isNaN)) return {};
  } catch (err) {
    return {};
  }
  return {
    r,
    g,
    b
  };
}

/**
 * @param {string} color - A color in hex, rgb(), or rgba() format
 * @param {number} opacity - A number between 0 (transparent) and 1 (opaque)
 * @returns {string} A string of the form "rgba(255, 150, 51, 0.75)"
 */
export function rgba(color, opacity = 1) {
  const {
    r,
    g,
    b
  } = getRGB(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
function getLuminance(hex) {
  const {
    r: rRaw,
    g: gRaw,
    b: bRaw
  } = getRGB(hex);
  const r = rRaw / 255.0;
  const rLuminance = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const g = gRaw / 255.0;
  const gLuminance = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const b = bRaw / 255.0;
  const bLuminance = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  return 0.2126 * rLuminance + 0.7152 * gLuminance + 0.0722 * bLuminance;
}

// https://www.w3.org/TR/WCAG21/#dfn-contrast-ratio
function getTextContrastRatio(...colors) {
  const [lighterColorLuminance, darkerColorLuminance] = colors.map(getLuminance).sort((a, b) => b - a);
  return (lighterColorLuminance + 0.05) / (darkerColorLuminance + 0.05);
}

// https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html
const AA_CONTRAST_THRESHOLD = 4.5;
export function canReadText(backgroundColor, textColor, threshold = AA_CONTRAST_THRESHOLD) {
  return getTextContrastRatio(backgroundColor, textColor) >= threshold;
}