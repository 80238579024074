import styled from 'styled-components';
import { getTheme } from './getTheme';

/**
 * Common HTML elements we want to support. Creating this type explicitly
 * seems to noticeably speed up TypeScript intellisense.
 */

const ELEMENTS_ARRAY = ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'big', 'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meta', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'tr', 'track', 'u', 'ul', 'use', 'var', 'video', 'wbr',
// SVG
'circle', 'clipPath', 'defs', 'ellipse', 'foreignObject', 'g', 'image', 'line', 'linearGradient', 'marker', 'mask', 'path', 'pattern', 'polygon', 'polyline', 'radialGradient', 'rect', 'stop', 'svg', 'text', 'tspan'];

// From Styled Components:
// https://github.com/styled-components/styled-components/blob/8b9d66f9a8e1dc59cfe5f5c4c079d8fcf5054e12/packages/styled-components/src/utils/domElements.ts
const ELEMENTS = new Set(ELEMENTS_ARRAY);

// Define an opaque type for Theme to compress error messages in TS

// Add type guard to check if something is a CSSObject
const isCSSObject = obj => {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
};

/**
 * Processes the styles for the styled function. This does a few things:
 *
 * 1. Injects the theme into the styles if the styles are a function
 *
 * @param styles - The styles to process.
 * @returns The processed styles.
 */
const processStyles = styles => {
  if (typeof styles === 'function') {
    return props => {
      const theme = getTheme({
        theme: props.theme
      });
      const computedStyles = styles(Object.assign({}, props, {
        theme
      }));
      if (typeof computedStyles === 'function') {
        return processStyles(computedStyles);
      }
      if (isCSSObject(computedStyles)) {
        return processStyles(computedStyles);
      }
      return computedStyles;
    };
  }
  return styles;
};

/**
 * Creates a wrapped styled function that calls our custom processStyles
 * function when styles are processed so we do a few custom things we need to
 * do like inject the theme and add prefix classes to the styles.
 *
 * @param originalStyledFn - The original styled function to wrap.
 * @returns A wrapped styled function that does extra processing on the styles.
 */
const createWrappedStyledFunction = originalStyledFn => {
  // Create a new style processing function that does our extra processing
  const wrappedFn = function wrappedFn(...args) {
    const [stylesOrTemplate, ...interpolations] = args;

    // Handle template strings
    if (Array.isArray(stylesOrTemplate)) {
      // Process each interpolation to inject theme if it's a function
      const processedInterpolations = interpolations.map(interpolation => {
        if (typeof interpolation === 'function') {
          return props => {
            const theme = getTheme({
              theme: props.theme
            });
            return interpolation(Object.assign({}, props, {
              theme
            }));
          };
        }
        return interpolation;
      });
      return originalStyledFn(stylesOrTemplate, ...processedInterpolations);
    }

    // Handle Styles
    const modifiedStyles = processStyles(stylesOrTemplate);
    return originalStyledFn(modifiedStyles);
  };

  // All styled components functions have a withConfig and attrs function.
  // We need to expose these in our style processing wrapper function so the
  // styled components API is mimicked

  // Just call through to the original withConfig method but return our style
  // processing wrapper function
  wrappedFn.withConfig = function (config) {
    return createWrappedStyledFunction(originalStyledFn.withConfig(config));
  };

  // Just call through to the original attrs method but return our style
  // processing wrapper function
  wrappedFn.attrs = function (attrs) {
    return createWrappedStyledFunction(originalStyledFn.attrs(attrs));
  };
  return wrappedFn;
};

/**
 * Creates a proxy to the styled function that overrides the element functions
 * and the default styled function that's used on custoam components. This
 * override does a few things
 *
 * 1. Automatically injects the theme into the component
 *
 * The proxy is nice here because it allows us to override the element functions
 * without having to worry about types or breaking other parts of the styled
 * components API. We could potentially just make a wrapper here that only
 * exposes the parts of styled components that we want to use but this is a
 * simpler change for now because it inherits the types from styled so it's more
 * of a drop in replacement.
 *
 * @example
 * ```tsx
 * // Call with default theme, no custom props
 * styledWithTheme.div(({ theme }) => {
 *   return {
 *     color: theme.color['text-core-default'],
 *   };
 * });
 *
 * // Call with default theme and custom props
 * styledWithTheme.div<CustomProps>(({ theme, $customProp }) => {
 *   console.log($customProp);
 *
 *   return {
 *     color: theme.color['text-core-default'],
 *   };
 * });
 * ```
 */
export const styledWithTheme = new Proxy(styled, {
  // Handle direct function calls like styled(Component)
  apply(target, thisArg, argumentsList) {
    const styledComponent = Reflect.apply(target, thisArg, argumentsList);
    const wrappedFn = createWrappedStyledFunction(styledComponent);
    return wrappedFn;
  },
  get(target, prop) {
    if (ELEMENTS.has(prop)) {
      const originalFn = target[prop];
      const wrappedFn = createWrappedStyledFunction(originalFn);
      return wrappedFn;
    }

    // Just call through to styled components
    return target[prop];
  }
});