/**
 * @param {KeyboardEvent} evt
 * @returns {boolean} `true` if any modifier (alt, ctrl, meta, or shift) is pressed
 */
export const isModifierPressed = evt => evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey;
export const stopPropagationHandler = evt => {
  if (evt) evt.stopPropagation();
};
export const repeatUntilEventOnElement = (callback, frequency, element, events) => {
  const handle = {
    active: true,
    count: 0,
    stop
  };
  const timer = setInterval(() => {
    callback();
    handle.count += 1;
  }, frequency);
  function stop() {
    handle.active = false;
    clearInterval(timer);
    events.forEach(event => {
      element.removeEventListener(event, stop);
    });
  }
  events.forEach(event => {
    element.addEventListener(event, stop);
  });
  return handle;
};
export const preventDefaultHandler = evt => {
  if (evt) evt.preventDefault();
};