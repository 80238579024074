import { css as styledComponentsCss } from 'styled-components';
import { getTheme } from './getTheme';

// Define an opaque type for Theme to compress error messages in TS

/**
 * Enhanced replacement for styled-components' `css` function that automatically
 * injects the theme into style functions. This follows the same pattern
 * as `styledWithTheme` to ensure consistent theme access.
 *
 * @example
 * ```tsx
 * // With template literal
 * const textStyles = css`
 *   color: ${({ theme }) => theme.color['text-core-default']};
 * `;
 *
 * // With object styles
 * const textStyles = css(({ theme }) => ({
 *   color: theme.color['text-core-default'],
 * }));
 *
 * // With conditional styling based on props
 * const dynamicStyles = css<{ $isActive: boolean }>`
 *   color: ${({ theme, $isActive }) =>
 *     $isActive ? theme.color['text-primary'] : theme.color['text-secondary']};
 * `;
 * ```
 */
export function css(first, ...interpolations) {
  // Handle template strings
  if (Array.isArray(first)) {
    // Process each interpolation to inject theme if it's a function
    const processedInterpolations = interpolations.map(interpolation => {
      if (typeof interpolation === 'function') {
        return props => {
          const theme = getTheme({
            theme: props.theme
          });
          return interpolation(Object.assign({}, props, {
            theme
          }));
        };
      }
      return interpolation;
    });
    return styledComponentsCss(first, ...processedInterpolations);
  }

  // Handle style objects or functions
  if (typeof first === 'function') {
    return styledComponentsCss(props => {
      const theme = getTheme({
        theme: props.theme
      });
      return first(Object.assign({}, props, {
        theme
      }));
    });
  }

  // Handle css object directly
  return styledComponentsCss(first);
}