// UIComponents runs in some environments where window.navigator is undefined.
// See: https://git.hubteam.com/HubSpot/hubspot-book-builder/pull/88
let lowercasedUserAgent = window.navigator ? navigator.userAgent.toLowerCase() : '';
export const isIE11 = () => lowercasedUserAgent.includes('trident/');
export const isEdge = () => lowercasedUserAgent.includes('edge/');
export const isMS = () => isIE11() || isEdge();
export const isFirefox = () => lowercasedUserAgent.includes('firefox');
export const isSafari = () => lowercasedUserAgent.includes('safari/') && !lowercasedUserAgent.includes('chrome/') && !isEdge();
export const getEdgeVersion = () => {
  const match = lowercasedUserAgent.match(/edge\/(\d+)/);
  return match ? +match[1] : undefined;
};
export const ignoresStyleTagInsertionOrder = () => {
  const edgeVersion = getEdgeVersion();
  return isIE11() || isEdge() && !!edgeVersion && edgeVersion < 17;
};

/**
 * For faking `navigator.userAgent` in tests.
 * @param {string} newUserAgent
 */
export const setUserAgent = newUserAgent => {
  lowercasedUserAgent = newUserAgent.toLowerCase();
};