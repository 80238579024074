'use es6';

import getDateNumberFormattingLocale from './getDateNumberFormattingLocale';
import getMomentToDateValue from './internal/getMomentToDateValue';
export default ((value, type, customTzName = '') => {
  const dateValue = getMomentToDateValue(value, type, customTzName);
  const dateFormatter = new Intl.DateTimeFormat(getDateNumberFormattingLocale(), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short'
  });
  return dateFormatter.format(dateValue);
});