export function getCoreComponent(Component) {
  let node = Component;
  while (node.WrappedComponent) {
    node = node.WrappedComponent;
  }
  return node;
}
export function isFunctionalComponent(Component) {
  return !Component.prototype || !Component.prototype.render;
}
export function attachWrappedComponent(Decorator, WrapperComponent) {
  Decorator.WrappedComponent = WrapperComponent;
  Decorator.CoreComponent = getCoreComponent(WrapperComponent);
}
export function copyMethods(wrappedInstance, decoratorInstance) {
  // Copy all methods from the wrapped component instance to this wrapper,
  // so that this wrapper is usable as a ref.
  if (!wrappedInstance) {
    return;
  }
  Object.getOwnPropertyNames(wrappedInstance).filter(key => key !== 'getDOMNode') // See #4515
  .forEach(key => {
    if (typeof wrappedInstance[key] === 'function' && !decoratorInstance[key]) {
      decoratorInstance[key] = wrappedInstance[key];
    }
  });
}
export const makeDecoratorRefCallback = (Component, decoratorInstance) => {
  if (isFunctionalComponent(Component)) return undefined;
  return ref => {
    copyMethods(ref, decoratorInstance);
  };
};