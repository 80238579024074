import PropTypes from 'prop-types';
import { useEffect } from 'react';

/** @internal */

const forEachClassName = (classNames, cb) => {
  classNames.trim().split(/\s+/).forEach(className => {
    cb(className);
  });
};
/**
 * Passes the child component through directly, while toggling
 * the body class according to the bodyClassName prop
 */
export default function ComponentWithBodyClass(props) {
  const {
    bodyElement,
    bodyClassName,
    children
  } = props;
  useEffect(() => {
    forEachClassName(bodyClassName, className => {
      bodyElement.classList.add(className);
    });
    return () => {
      forEachClassName(bodyClassName, className => {
        bodyElement.classList.remove(className);
      });
    };
  }, [bodyClassName, bodyElement]);
  return children;
}
ComponentWithBodyClass.propTypes = {
  bodyClassName: PropTypes.string.isRequired,
  bodyElement: PropTypes.object.isRequired,
  children: PropTypes.node
};

/* eslint-disable-next-line hubspot-dev/no-default-props-assignment */
ComponentWithBodyClass.defaultProps = {
  bodyElement: document.body
};
ComponentWithBodyClass.displayName = 'ComponentWithBodyClass';