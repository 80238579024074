export const SIZE_OPTIONS = {
  xs: '20px',
  sm: '28px',
  md: '40px'
};
export const getSpinnerSize = (size = 'sm') => {
  return SIZE_OPTIONS[size];
};
export const getIconColor = use => {
  switch (use) {
    case 'danger':
      return 'fill-alert-default';
    case 'primary':
    case 'secondary':
      return 'fill-brand-default';
    case 'secondary-ghost':
      return 'fill-surface-default';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return 'fill-accent-neutral-default';
    case 'success':
      return 'fill-positive-default';
    case 'link':
    default:
      return 'fill-info-default';
  }
};
export const getIconSize = size => {
  switch (size) {
    case 'xs':
    case 'extra-small':
      return '500';
    case 'md':
    case 'medium':
      return '800';
    case 'sm':
    case 'small':
    default:
      return '700';
  }
};