import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Metrics } from '../utils/Metrics';

// only expected to be unknown in jest
let packageName = 'unknown';
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  packageName = require('current-package-loader!').name;
} catch (e) {
  // ignore
}
let hasSeenReady = false;

/**
 * Emit metrics to help find places where apps aren't properly configured to
 * switch over to foundations components
 */
export function useFoundationsReadinessTracking() {
  const theme = useTheme();
  useEffect(() => {
    const dimensions = {
      hasSeenReady: `${hasSeenReady}`,
      packageName
    };
    try {
      var _bender$templateBasen, _window;
      dimensions.templateBasename = // eslint-disable-next-line hubspot-dev/hubspot-is-special
      (_bender$templateBasen = (_window = window) === null || _window === void 0 || (_window = _window.hubspot) === null || _window === void 0 || (_window = _window.bender) === null || _window === void 0 ? void 0 : _window.templateBasename) !== null && _bender$templateBasen !== void 0 ? _bender$templateBasen : 'unknown';
    } catch (e) {
      /* ignore */
    }
    try {
      var _color;
      const isProbablySetUpForFoundationsComponents = typeof theme === 'object' && Object.keys(theme !== null && theme !== void 0 ? theme : {}).includes('color') && typeof theme.color === 'object' && Object.keys((_color = theme.color) !== null && _color !== void 0 ? _color : {}).includes('icon-primary-on-fill-default');
      if (isProbablySetUpForFoundationsComponents) {
        hasSeenReady = true;
        delete dimensions.hasSeenReady;
      }
      dimensions.result = `${isProbablySetUpForFoundationsComponents}`;
    } catch (__e) {
      // It's fine, this is very best effort,
      // we just don't want to ever crash an app.
      // If we threw, then this app probably isn't set up though.
      dimensions.result = 'false';
      dimensions.error = 'true';
    }
    Metrics.counter('foundations-theme-ready', dimensions).increment();
  }, [theme]);
}