export const WEIGHTS = {
  regular: 'regular',
  medium: 'medium',
  bold: 'bold',
  demi: 'demi'
};
export const USES = {
  error: 'error',
  help: 'help',
  success: 'success'
};