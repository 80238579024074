export default {
  "zh-hk": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u %n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "HK$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": "、",
            "twoWordsConnector": "和",
            "lastWordConnector": "和"
          },
          "exclusive": {
            "wordsConnector": "、 ",
            "twoWordsConnector": "或",
            "lastWordConnector": "或"
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "other": "0 千"
            },
            "10000": {
              "other": "0萬"
            },
            "100000": {
              "other": "00萬"
            },
            "1000000": {
              "other": "000萬"
            },
            "10000000": {
              "other": "0000萬"
            },
            "100000000": {
              "other": "0億"
            },
            "1000000000": {
              "other": "00億"
            },
            "10000000000": {
              "other": "000億"
            },
            "100000000000": {
              "other": "0000億"
            },
            "1000000000000": {
              "other": "0兆"
            },
            "10000000000000": {
              "other": "00兆"
            },
            "100000000000000": {
              "other": "000兆"
            }
          },
          "short": {
            "1000": {
              "other": "0K"
            },
            "10000": {
              "other": "00K"
            },
            "100000": {
              "other": "000K"
            },
            "1000000": {
              "other": "0M"
            },
            "10000000": {
              "other": "00M"
            },
            "100000000": {
              "other": "000M"
            },
            "1000000000": {
              "other": "0B"
            },
            "10000000000": {
              "other": "00B"
            },
            "100000000000": {
              "other": "000B"
            },
            "1000000000000": {
              "other": "0T"
            },
            "10000000000000": {
              "other": "00T"
            },
            "100000000000000": {
              "other": "000T"
            }
          }
        }
      }
    }
  }
}