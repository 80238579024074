let _ = t => t,
  _t;
import { PANEL_WITH_SCROLL_CLASS, QUARTZ_APP_CONTAINER_CLASS, QUARTZ_GRID_LAYOUT_CLASS, QUARTZ_SIDEBAR_CONTAINER_CLASS, SIDEBAR_CLASS } from 'quartz/grid';
import { createGlobalStyle } from 'styled-components';
/**
 * Add styles to the Quartz layout components
 *
 * 1. PANEL_WITH_SCROLL_CLASS - For the panel-with-scroll layout, adds a  grid
 * layout where the first section (the app) gets all the space in the parent
 * container and the 2nd section (the sidebar spacer) gets no space. This
 * places the sidebar spacer offscreen so we can scroll over to it without
 * scrolling the app.
 *
 * 2. SIDEBAR_CLASS - For the sidebar layout, adds a grid layout where the first
 * section (the app) gets all the space in the parent container but the sidebar
 * has a higher priority for space when it needs it (auto). This lets the
 * sidebar "push" the app over when it render.
 *   2a. SIDEBAR_CONTAINER_CLASS - The sidebar container is given a
 *       width and overflow hidden when it starts open to reserve space for the
 *       sidebar to open in when it loads.
 *
 * 3. QUARTZ_APP_CONTAINER_CLASS - The app container is given a min-width and
 * min-height so it works in flex containers, we also set the overflow-y to auto
 * so scrollbars will appear for the app in the app window and not the main
 * window.
 */
export const GridStyles = createGlobalStyle(_t || (_t = _`
  .${0} {
    &.${0} {
      display: grid;
      max-width: 100%;
      grid-template-columns: 1fr 0px;
    }
    &.${0} {
      display: grid;
      max-width: 100%;
      grid-template-columns: 1fr auto;
      > .${0} {
        background: #FFF;
        border-radius: 16px 0px 0px 16px;
        ${0}
      }
    }
    > .${0} {
      min-width: 0;
      min-height: 0;
      overflow-y: auto;
      max-height: inherit;
    }
  }
`), QUARTZ_GRID_LAYOUT_CLASS, PANEL_WITH_SCROLL_CLASS, SIDEBAR_CLASS, QUARTZ_SIDEBAR_CONTAINER_CLASS, ({
  shouldSidebarStartOpen
}) => shouldSidebarStartOpen && `
          width: 400px;
          overflow: hidden;
        `, QUARTZ_APP_CONTAINER_CLASS);
export const setupGridClasses = layoutType => {
  const gridContainer = document.querySelector(`.${QUARTZ_GRID_LAYOUT_CLASS}`);
  const appContainer = document.querySelector(`.${QUARTZ_APP_CONTAINER_CLASS}`);

  // Make sure both the required layout containers are here before trying to
  // render a sidebar layout
  if (gridContainer && appContainer) {
    if (layoutType === 'panel-with-scroll') {
      gridContainer.classList.add(PANEL_WITH_SCROLL_CLASS);
      return;
    } else if (layoutType === 'sidebar') {
      gridContainer.classList.add(SIDEBAR_CLASS);
      return;
    }
  }
  const maybeSidebarElement = document.querySelector(`.${QUARTZ_SIDEBAR_CONTAINER_CLASS}`);

  // Move the sidebar element to the body so it doesn't get deleted by the app
  // when it renders. It doesn't matter where the element is as long as it's
  // on the page for the panel layout so we just put it on the body
  if (maybeSidebarElement) {
    document.body.appendChild(maybeSidebarElement);
  }
  return;
};