export default {
  "ja": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 0,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "¥"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": "バイト",
            "gb": "ギガバイト",
            "kb": "キロバイト",
            "mb": "メガバイト",
            "tb": "テラバイト"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": "と",
            "twoWordsConnector": "と",
            "lastWordConnector": "と"
          },
          "exclusive": {
            "wordsConnector": "または",
            "twoWordsConnector": "または",
            "lastWordConnector": "または"
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "other": "0千"
            },
            "10000": {
              "other": "0万"
            },
            "100000": {
              "other": "00万"
            },
            "1000000": {
              "other": "000万"
            },
            "10000000": {
              "other": "0000万"
            },
            "100000000": {
              "other": "0億"
            },
            "1000000000": {
              "other": "00億"
            },
            "10000000000": {
              "other": "000億"
            },
            "100000000000": {
              "other": "0000億"
            },
            "1000000000000": {
              "other": "0兆"
            },
            "10000000000000": {
              "other": "00兆"
            },
            "100000000000000": {
              "other": "000兆"
            }
          },
          "short": {
            "1000": {
              "other": "0千"
            },
            "10000": {
              "other": "0万"
            },
            "100000": {
              "other": "00万"
            },
            "1000000": {
              "other": "000万"
            },
            "10000000": {
              "other": "0000万"
            },
            "100000000": {
              "other": "0億"
            },
            "1000000000": {
              "other": "00億"
            },
            "10000000000": {
              "other": "000億"
            },
            "100000000000": {
              "other": "0000億"
            },
            "1000000000000": {
              "other": "0兆"
            },
            "10000000000000": {
              "other": "00兆"
            },
            "100000000000000": {
              "other": "000兆"
            }
          }
        }
      }
    }
  }
}