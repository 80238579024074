import PropTypes from 'prop-types';
import createChainablePropType from './createChainablePropType';
let illustrationNames;
let propType;
export function getUniqueIllustrationNames() {
  if (!illustrationNames) {
    illustrationNames = process.env.NODE_ENV === 'production' ? [] :
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    Object.keys(require('ui-images/metadata').illustrations);
  }
  return illustrationNames;
}
export function getIllustrationNamePropType() {
  if (!propType) {
    propType = PropTypes.oneOf(getUniqueIllustrationNames());
  }
  return createChainablePropType(propType, 'illustrationName');
}