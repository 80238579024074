import { createGlobalStyle } from 'styled-components';
import { useIsKeyboardMode } from './KeyboardModeProvider';
export const GlobalStyles = createGlobalStyle(({
  theme: _theme
}) => {
  const theme = _theme;
  const isKeyboardMode = useIsKeyboardMode();
  return {
    /*
     * DO NOT ADD TO THIS COMPONENT UNLESS ABSOLUTELY NECESSARY,
     * INCREASES IN FILE SIZE CAN RESULT IN SEVERE PERFORMANCE ISSUES DOWNSTREAM
      * See: https://hubspot.slack.com/archives/C0373M25H8F/p1741099985533919
    */

    /*
     * Removes default focus styles for non-input elements.
     * This is the replacement for the `hubspot-disable-focus-styles` CSS class in UIComponents.
     */
    '*:focus:not(input):not(textarea):not(.keep-default-focus-styles)': {
      outline: isKeyboardMode ? undefined : '0'
    },
    // Modifier to suppress navigation (used by the transitional EditorPage)
    '.space-sword--editor': {
      '#hs-nav-v4, #placeholder-vertical-nav, #trial-banner-block': {
        display: 'none'
      },
      '.page, .app': {
        marginInlineStart: theme.space['0'],
        inlineSize: 'auto'
      }
    }
  };
});