export default {
  "ms": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u %n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "RM"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ",",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": "bait",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " dan ",
            "lastWordConnector": " dan "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " atau ",
            "lastWordConnector": ", atau "
          }
        }
      }
    }
  }
}