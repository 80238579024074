import { useCallback, useEffect, useState } from 'react';
const keyTogglesActive = ({
  key
}) => key === 'Enter' || key === ' ';
export default function useActive({
  active,
  onMouseDown,
  onKeyDown,
  onKeyUp,
  onBlur
}) {
  const [activeState, setActiveState] = useState(false);
  const handleMouseDown = useCallback(evt => {
    if (onMouseDown) onMouseDown(evt);
    setActiveState(true);
  }, [onMouseDown]);
  const handleKeyDown = useCallback(evt => {
    if (onKeyDown) onKeyDown(evt);
    if (keyTogglesActive(evt)) setActiveState(true);
  }, [onKeyDown]);
  const handleKeyUp = useCallback(evt => {
    if (onKeyUp) onKeyUp(evt);
    if (keyTogglesActive(evt)) setActiveState(false);
  }, [onKeyUp]);
  const handleBlur = useCallback(evt => {
    if (onBlur) onBlur(evt);
    setActiveState(false);
  }, [onBlur]);

  // After `mousedown` on the wrapped element, `mouseup` may occur elsewhere in the document.
  useEffect(() => {
    if (!activeState) return undefined;
    const handleMouseUp = () => {
      setActiveState(false);
    };
    addEventListener('mouseup', handleMouseUp);
    return () => {
      removeEventListener('mouseup', handleMouseUp);
    };
  }, [activeState]);
  return {
    active: active != null ? active : activeState,
    onMouseDown: activeState ? onMouseDown : handleMouseDown,
    onKeyDown: activeState ? onKeyDown : handleKeyDown,
    onKeyUp: activeState ? handleKeyUp : onKeyUp,
    onBlur: activeState ? handleBlur : onBlur
  };
}